import React, { useState } from 'react';
import { AppRoutes } from './routes/AppRoutes';
import { AuthProvider } from './routes/contexts/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Header } from './components/Header';
import './App.css';

export default function App() {
  
  return (
    <AuthProvider>
      <BrowserRouter>
      <ToastContainer />
      <Header />
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>   
  );
};