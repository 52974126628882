import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { FaTrash, FaPlus } from 'react-icons/fa';
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../../service/firebaseConfig';

import { AuthContext } from '../../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import Toast from "../../../components/Toast";
import { doc, setDoc, getDoc, collection, deleteDoc, getDocs, updateDoc } from "firebase/firestore";

import '../styles.css';

export const Moradores = () => {

  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const location = useLocation();
  const cnpj = location.state ? location.state.cnpj : null;
  const [atualizar, setAtualizar] = useState(false);
    const [emailMorador, setEmailMorador] = useState([]);
    const [qtdMoradores, setQtdMoradores] = useState(false);
    const [moradoresCadastrados, setMoradoresCadastrados] = useState(null);
    const [adicionarMorador, setAdicionarMorador] = useState(false);

    useEffect(()=> {
        async function buscaPorteiros() {
            await buscaDadosMoradores();
        }
        buscaPorteiros();
      
    },[atualizar]);

    async function buscaDadosMoradores(){
        // fazer igual do controle interno, não vai ter mais tabela separada, vai ser uma
// sub-coleção na tabela contratantes
            
    }

    async function incluirMorador() {
// fazer igual do controle interno, não vai ter mais tabela separada, vai ser uma
// sub-coleção na tabela contratantes
        
    }

    async function removerMorador(index) {
        // fazer igual do controle interno, não vai ter mais tabela separada, vai ser uma
// sub-coleção na tabela contratantes
    }

    function handleAdicionarMorador() {
        setAdicionarMorador(true);
    }
 
  return (
    <div className="MenuAdmin">
        <div>
            {moradoresCadastrados? (
                <div>
                    <h4>Moradores cadastrados:</h4>
                {moradoresCadastrados.map((email, index) => (
                    <div key={index} className='lista'>
                    <span>Email do morador {index + 1}: {email}</span>
                    <button onClick={() => removerMorador(index)} className="remover">
                        <FaTrash />
                    </button>
                    </div>
                )
                )}
                </div>
            ): (<div></div>)}
            <h4>adicionar morador</h4>
            <button className="adicionar" onClick={() => handleAdicionarMorador()}>
                <FaPlus />
                Adicionar morador
            </button>
            {adicionarMorador ? (
                <div className='cadastro'>
                    <label>*Quantidade moradores</label>
                    <input
                    placeholder="digite a quantidade de moradores"
                    value={qtdMoradores}
                    onChange={(e) => setQtdMoradores(e.target.value)}
                    autoCapitalize="none"
                    type="number"
                    />
                    {Array.from({ length: parseInt(qtdMoradores) }).map((_, index) => (
                    <div key={index}>
                        <label>*Email do morador {index + 1}</label>
                        <input
                        placeholder={`Digite o email do morador ${index + 1}`}
                        value={emailMorador[index] || ''}
                        onChange={(e) => {
                            const updatedEmailMorador = [...emailMorador];
                            updatedEmailMorador[index] = e.target.value;
                            setEmailMorador(updatedEmailMorador);
                        }}
                        autoCapitalize="none"
                        type="email"
                        />
                    </div>
                    ))}
                    <button className="home-button" onClick={incluirMorador}>
                    Incluir moradores
                    </button>
                </div>
            ) : <div></div>}
        </div>
    </div>
  )
};
