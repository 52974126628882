import React, { useState, useEffect, useContext, useRef } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { FaTrash, FaPlus } from 'react-icons/fa';
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../../service/firebaseConfig';

import { AuthContext } from '../../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import Toast from "../../../components/Toast";
import { doc, setDoc, getDoc, collection, deleteDoc, getDocs, updateDoc } from "firebase/firestore";

import { validar } from '../../../components/utils';

import '../styles.css';

export const Porteiro = () => {

  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const location = useLocation();
  const cnpj = location.state ? location.state.cnpj : null;
  const [atualizar, setAtualizar] = useState(false);
    
    const [emailPorteiro, setEmailPorteiro] = useState([]);
    const [qtdPorteiros, setQtdPorteiros] = useState(false);
    const [porteirosCadastrados, setPorteirosCadastrados] = useState(null);
    const [adicionarPorteiro, setAdicionarPorteiro] = useState(false);
    const [verPorteirosCadastrados, setVerPorteirosCadastrados] = useState(false);

    const [qtdPorteirosCadastrados, setQtdPorteirosCadastrados] = useState(0);

    useEffect(()=> {
        async function buscaPorteiros() {
            await buscaDadosPorteiro();
        }
        buscaPorteiros();
      
    },[atualizar]);

    async function buscaDadosPorteiro(){
        try {
            const colectionRef = collection(db, 'contratantes', cnpj, 'porteiro');
            const querySnapshot = await getDocs(colectionRef);
            const porteiros = [];
            setQtdPorteirosCadastrados(querySnapshot.docs.length);

            if (!querySnapshot.empty) {
                await Promise.all(
                    querySnapshot.docs.map(async (doc) => {
                        const porteiroData = doc.data();

                    // Extrair os campos email, cpf, nome e folga
                        const porteiro = {
                        email: porteiroData.email, // o ID do documento é o email
                        };

                    // Adiciona o funcionário ao array de funcionários
                        porteiros.push(porteiro);
                    })
                );

            // Atualiza o estado com o array de funcionários cadastrados
                setPorteirosCadastrados(porteiros);
            } else {
                setPorteirosCadastrados(null)
            }
        } catch (error) {
            console.error("Erro ao buscar dados da empresa:", error);
        }
    }

    async function incluirPorteiro() {
        let valido = true
        await Promise.all(
            emailPorteiro.map(async (email, index) => {
                const dados = {
                    email: emailPorteiro[index],
                }
                
                const isValid = validar(dados);

                if (!isValid) {
                // Buscar o cadastro para o email atual
                    valido = false
                }
            })
        );
        if (valido) {
            await Promise.all(
                emailPorteiro.map(async (email, index) => {
                    // Buscar o cadastro para o email atual
                    try {

                    const porteiroTab = collection(db, 'contratantes', cnpj, 'porteiro');
                    const incluirPorteiroTab = doc(porteiroTab, email)

                    let tabPorteiroData = {
                        email: emailPorteiro[index], // Email
                    };

                    await setDoc(incluirPorteiroTab, tabPorteiroData);

                    const usuarioTab = collection(db, 'usuarios', emailPorteiro[index], 'porteiro');
                    const incluirUsuarioTab = doc(usuarioTab, cnpj)

                    let tabUsuarioData = {
                        ativo: true
                    };

                    await setDoc(incluirUsuarioTab, tabUsuarioData)
                    Toast.success('Recepcionista incluidos com sucesso.');
                    setAdicionarPorteiro(false)
                    setAtualizar(!atualizar)
                    } catch (error) {
                        console.log('error = ', error)
                        toast.error('Erro ao incluir Recepcionista.');
                    }
                })
            );
        }
        //fazer igual ao controle interno
    }

    async function removerPorteiro(index) {
        try {
            const emailPorteiro = porteirosCadastrados[index].email; // Obtém o email do funcionário a ser removido
                
            // Caminho para o documento do funcionário dentro da subcoleção
            const docRef = doc(db, 'contratantes', cnpj, 'porteiro', emailPorteiro);
    
            // Exclui o documento do funcionário
            await deleteDoc(docRef);

            const usuRef = doc(db, 'usuarios', emailPorteiro, 'porteiro', cnpj);

            await deleteDoc(usuRef);
    
            // Remover funcionário da lista exibida no estado
            const novaLista = [...porteirosCadastrados];
            novaLista.splice(index, 1);
            setPorteirosCadastrados(novaLista);
    
            // Exibir feedback de sucesso
            Toast.success('Recepcionista removido com sucesso!');
        } catch (error) {
            console.error("Erro ao remover recepcionista:", error);
            Toast.error('Erro ao remover recepcionista.');
        }
        //fazer igual ao controle interno
    }

    function handleAdicionarPorteiro() {
        setAdicionarPorteiro(true);
    }
 
  return (
    <div className="MenuAdmin">
        <h1>Quantidade de recepicionista cadastrados: {qtdPorteirosCadastrados}</h1>
        {!verPorteirosCadastrados ? (
            <button onClick={() => setVerPorteirosCadastrados(true)}>Ver recepcionista</button>
        ) : (
            <div>
                <button onClick={() => setVerPorteirosCadastrados(false)}>Esconder recepcionista</button> 
                
                {porteirosCadastrados? (
                    <div>
                        <h4>Porteiros/Recepcionistas cadastrados:</h4>
                        {porteirosCadastrados.map((porteiro, index) => (
                            <div key={index} className='lista'>
                                <span>Email do porteiro {index + 1}: {porteiro.email}</span> {/* Acessar a propriedade 'email' */}
                                <button onClick={() => removerPorteiro(index)} className="remover">
                                <FaTrash />
                                </button>
                            </div>
                        ))}
                    </div>
                ): (<div></div>)}
            </div>
        )}

        <h4>adicionar recepcionista</h4>
        <button className="adicionar" onClick={() => handleAdicionarPorteiro()}>
            <FaPlus />
            Adicionar recepcionista
        </button>
        {adicionarPorteiro ? (
            <div className='cadastro'>
                <label>*Quantidade Recepcionista</label>
                <input
                placeholder="digite a quantidade de Recepcionistas"
                value={qtdPorteiros}
                onChange={(e) => setQtdPorteiros(e.target.value)}
                autoCapitalize="none"
                type="number"
                />
                {Array.from({ length: parseInt(qtdPorteiros) }).map((_, index) => (
                <div key={index}>
                    <label>*Email do recepcionista {index + 1}</label>
                    <input
                    placeholder={`Digite o email do recepcionista ${index + 1}`}
                    value={emailPorteiro[index] || ''}
                    onChange={(e) => {
                        const updatedEmailPorteiro = [...emailPorteiro];
                        updatedEmailPorteiro[index] = e.target.value;
                        setEmailPorteiro(updatedEmailPorteiro);
                    }}
                    autoCapitalize="none"
                    type="email"
                    />
                </div>
                ))}
                <button className="adicionar" onClick={incluirPorteiro}>
                Incluir recepcionista
                </button>
            </div>
        ) : <div></div>}
    </div>
  )
};
