import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import { doc, getDoc, addDoc, collection, where, query, getDocs } from 'firebase/firestore';
import { db } from '../../service/firebaseConfig';
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { AuthContext } from '../../routes/contexts/AuthContext';

import Select from 'react-select';


import './styles.css';

export const Qrcode = () => {
  const { currentUser } = useContext(AuthContext);
  const { qrCodeResult } = useParams()
  const [cnpj, setCnpj ] = useState('')
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [empresaOk, setEmpresaOk] = useState(false);
  const [data, setData] = useState('');
  const [entradaAutomatica, setEntradaAutomatica] = useState(false);
  const [entradaPorteiro, setEntradaPorteiro] = useState(false);

  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cel, setCel] = useState('');
  const [uf, setUf] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [cep, setCep] = useState('');
  const [end, setEnd] = useState('');
  const [tipoDoc, setTipoDoc] = useState('');
  const [numDoc, setNumDoc] = useState('');
  const [placa, setPlaca] = useState('');
  const [profissao, setProfissao] = useState('');
  const [genero, setGenero] = useState('');
  const [foto, setFoto] = useState(null);
  const [fotoUrl, setFotoUrl] = useState('');

  let validaErro = false

  const [camposObrigatorios, setCamposObrigatorios] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  const campoNomes = {
    cel: 'Número de Celular',
    cep: 'CEP',
    cpf: 'CPF',
    dataNascimento: 'Data de Nascimento',
    email: 'Endereço de Email',
    end: 'Endereço',
    foto: 'Foto do documento',
    genero: 'Gênero',
    nome: 'Nome',
    numDoc: 'Número do Documento',
    profissao: 'Profissão',
  };

  const ordemCampos = ['email', 'nome', 'cpf', 'dataNascimento', 'cel',  'cep', 'end', 'profissao', 'genero', 'numDoc',  'foto'];

  const tiposDoc = [
    { value: 1, label: 'Identidade' },
    { value: 2, label: 'CNH' },
    { value: 3, label: 'Passaporte' },
    { value: 4, label: 'Carteira de trabalho' },
  ];

  const [selectedDoc, setSelectedDoc] = useState(null);

  let dadosUsu = {}

  const [nomeObr, setNomeObr] = useState(false);
  const [cpfObr, setCpfObr] = useState(false);
  const [dataNascimentoObr, setDataNascimentoObr] = useState(false);
  const [celObr, setCelObr] = useState(false);
  const [cepObr, setCepObr] = useState(false);
  const [endObr, setEndObr] = useState(false);
  const [numDocObr, setNumDocObr] = useState(false);
  const [placaObr, setPlacaObr] = useState(false);
  const [profissaoObr, setProfissaoObr] = useState(false);
  const [generoObr, setGeneroObr] = useState(false);
  const [fotoObr, setFotoObr] = useState(false);
  const [emailObr, setEmailObr] = useState(false);
  const [dadosCarregados, setDadosCarregados] = useState(false);



  useEffect(() => {
    if (currentUser){
      setEmail(currentUser.email)
      buscaDadosUsuario();
    }
    async function empresa() {
      await buscaDados();
    }
    empresa();
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
    const localDate = new Date(currentDate - timezoneOffset);
    const formattedDate = localDate.toISOString().slice(0, 10);
    setData(formattedDate);
  }, []);

  async function buscaDados() {
    const cnpjNumerico = qrCodeResult.replace(/\D/g, '');
    setCnpj(cnpjNumerico)

    const docRef = doc(db, 'contratantes', cnpjNumerico);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setNomeEmpresa(docSnap.data().nome);
      setCamposObrigatorios(docSnap.data().camposObrigatorios);
      setEmpresaOk(true);
      setEntradaAutomatica(docSnap.data().entradaAutomatica);
      setEntradaPorteiro(docSnap.data().entradaPorteiro);
      verCamposObrigatorios(docSnap.data().camposObrigatorios);
      setDadosCarregados(true);
    } else {
      toast.error('Empresa não cadastrada no check-in Facil.');
    }
  };

  function verCamposObrigatorios(campos) {
    const camposTrue = Object.keys(campos).filter(
      (campo) => campos[campo] === true
    );

    camposTrue.forEach((campo) => {
      switch (campo) {
        case 'cpf':
          setCpfObr(true);
          break;
        case 'email':
          setEmailObr(true);
          break;
        case 'cel':
          setCelObr(true);
          break;
        case 'cep':
          setCepObr(true);
          break;
        case 'dataNascimento':
          setDataNascimentoObr(true);
          break;
        case 'end':
          setEndObr(true);
          break;
        case 'genero':
          setGeneroObr(true);
          break;
        case 'nome':
          setNomeObr(true);
          break;
        case 'numDoc':
          setNumDocObr(true);
          break;
        case 'profissao':
          setProfissaoObr(true);
          break;
        case 'placa':
          setPlacaObr(true);
          break;
        case 'foto':
          setFotoObr(true);
          break;
        default:
          break;
      }
    });
  }

  // Renderiza um loader enquanto os dados não foram carregados
  if (!dadosCarregados) {
    return <div>Carregando...</div>;
  }

  async function buscaDadosUsuario(){
    const docRef = doc(db, 'usuarios', currentUser.email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      dadosUsu = docSnap.data()
      setNome(docSnap.data().nome)
      setCpf(docSnap.data().cpf)
      setDataNascimento(docSnap.data().dataNascimento)
      setCel(docSnap.data().cel)
      setGenero(docSnap.data().genero)
      setCep(docSnap.data().cep)
      setUf(docSnap.data().estado)
      setCidade(docSnap.data().cidade)
      setEnd(docSnap.data().end)
      setTipoDoc(docSnap.data().tipoDoc)
      setNumDoc(docSnap.data().numDoc)
      setProfissao(docSnap.data().profissao)
      setPlaca(docSnap.data().placa)
      
    }
  }

  const handleSelectDoc = (selectedDoc) => {
    setSelectedDoc(selectedDoc);
    setTipoDoc(selectedDoc.value)
  };

  
  const camposObrigatoriosTrue = Object.keys(camposObrigatorios).filter(
    (campo) => camposObrigatorios[campo]
  );

  async function handleSignInWithEmailAndPassword(e) {
    e.preventDefault();
    signInWithEmailAndPassword(email, password)
  .then((userCredential) => {
    // Signed in
    const user = userCredential.user;
    Toast.success("Login efetuado com sucesso!");
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    toast.error("Email ou senha inválida.");
    return;
  });
  }

  async function handleEntrar(e) {
    e.preventDefault();
    validaCampos()
    if (!validaErro){
      const visitasCadastradas = await buscarVisitaCadastrada();
      let emailMorador2 = ''
      if (!visitasCadastradas.empty) {
        // Iterar sobre os documentos
        visitasCadastradas.forEach((doc) => {
          const emailMorador = doc.data().emailMorador;
          emailMorador2 = emailMorador
        })
      }
      if (entradaAutomatica) {
        if (!visitasCadastradas.empty) {
          toast.error('Visita não cadastrada para esse email.');
        }else{

          try {
            let dados = {
              nome: nome,
              cpf: cpf,
              dataNascimento: dataNascimento,
              cel: cel,
              uf: uf,
              cidade: cidade,
              cep: cep,
              end: end,
              tipoDoc: tipoDoc,
              numDoc: numDoc,
              placa: placa,
              profissao: profissao,
              genero: genero,
              cnpj: cnpj,
              data: data,
              email: email,
              emailMorador: emailMorador2,
              autorizado: true,

            } 
            const docRef = await addDoc(collection(db, "entradas"), dados); 

            toast.info('Entrada automatica solicitada, portão vai abrir em breve');
            Toast.success('Pedido de check-in incluido com sucesso.')
          //    navigate('/home')
          
          } catch (error) {
            console.log('erro1 = ', error)
            toast.error('Erro ao incluir entrada automatica.')
          }
        }
      }
      
      if (entradaPorteiro) {

        try {
          let dados = {
            nome: nome,
            cpf: cpf,
            dataNascimento: dataNascimento,
            cel: cel,
            uf: uf,
            cidade: cidade,
            cep: cep,
            end: end,
            tipoDoc: tipoDoc,
            numDoc: numDoc,
            placa: placa,
            profissao: profissao,
            genero: genero,
            cnpj: cnpj,
            data: data,
            email: email,
            emailMorador: emailMorador2,
            autorizado: false,

          } 

          const docRef = await addDoc(collection(db, "pedidos"), dados); 

          Toast.success('Pedido de check-in incluido com sucesso.')
        //    navigate('/home')
        
        } catch (error) {
          console.log('erro2 = ', error)
          toast.error('Erro ao incluir pedido de check-in.')
        }
      }
        
    }
  }

  async function buscarVisitaCadastrada(){
    const buscaVisita = collection(db, 'visitas');
      const q = query(
        buscaVisita,
        where('cnpj', '==', cnpj),
        where('dataVisita', '==', data),
        where('email', 'array-contains', email)
      );
      try {
        const visitasCadastradasSnapshot = await getDocs(q);
        return visitasCadastradasSnapshot;

      } catch (error){
        toast.error('Entrada não autorizada para o seu email.')
      }
  }

  function validaCampos(){
    validaErro = false;
    camposObrigatoriosTrue.forEach(item => {
      
        if (!/\S+@\S+\.\S+/.test(email)) {
          toast.error("Por favor, digite um email válido.");
          validaErro = true
          return !validaErro;
        }
      
      if (item == 'cpf') {
        if (!validarCPF(cpf)) {
          toast.error("CPF inválido. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'cel') {
        if (!validarCel(cel) ){
          toast.error("Celular invalido. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'cep') {
        if (cep === '' ){
          toast.error("cep não informado. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'dataNascimento') {
        if (dataNascimento === '' ){
          toast.error("Data de nascimento não informada. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'end') {
        if (end === '' ){
          toast.error("Endereço não informado. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'genero') {
        if (genero === '' ){
          toast.error("Genero não informado. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'nome') {
        if (nome === '' ){
          toast.error("Nome não informado. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'numDoc') {
        if (numDoc === '' ){
          toast.error("Numero de documento não informado. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'profissao') {
        if (profissao === '' ){
          toast.error("Profissão não informada. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
      if (item == 'placa') {
        if (placa === '' ){
          toast.error("placa não informada. Atulize seu cadastro.");
          validaErro = true
          return !validaErro
        }
      }
    });
  }

  function validarCPF(cpf) {
    cpf = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  
    if (cpf === '' || cpf.length !== 11) {
      // CPF não informado ou com tamanho inválido
      return false;
    }
  
    // Verifica se todos os dígitos são iguais, o que torna o CPF inválido
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
  
    // Validação dos dígitos verificadores
    let soma = 0;
    let resto;
  
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
  
    soma = 0;
  
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseInt(cpf.substring(10, 11))) {
      return false;
    }
  
    return true;
  }

  function validarCel(cel) {
    cel = cel.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  
    if (cel === '' || cel.length != 11) {
      // cel não informado ou com tamanho inválido
      return false;
    }
  
    // Verifica se todos os dígitos são iguais, o que torna o CPF inválido
    if (/^(\d)\1+$/.test(cel)) {
      return false;
    }
  
    return true;
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    
    if (selectedFile) {
      // Defina a foto em setFoto
      setFoto(selectedFile);
      
      // Gere a URL da foto e defina-a em setFotoUrl
      const imageUrl = URL.createObjectURL(selectedFile);
      setFotoUrl(imageUrl);
    }else{
      setFoto(null)
      setFotoUrl('')
    }
  };

  return (
    <div className='qrcode'>
      {empresaOk ? (
        <div>
          <h1>{nomeEmpresa}</h1>
          {camposObrigatoriosTrue.length > 0 && (
            <div>
              <h4>Esse local exige os seguintes dados:</h4>
              <ul>
                {ordemCampos.map((campo, index) => (
                  camposObrigatoriosTrue.includes(campo) && (
                    <li key={index}>{campoNomes[campo]} </li>
                  )
                ))}
              </ul>
            </div>
          )}
          {!currentUser ? (
            <div className='login'>
              <h2>Você não está logado</h2>
              <form>
                <div className="login-input">
                  <label htmlFor="email">E-mail</label>
                  <input
                    type="email"
                    autoCapitalize="none"
                    name="email"
                    id="email"
                    placeholder="SeuEmail@xxx.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="login-input">
                  <label htmlFor="password">Senha</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="********************"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button className="login-button" onClick={handleSignInWithEmailAndPassword}>
                  Entrar
                </button>
              </form>
              <div className="login-footer">
                <p>Você não tem uma conta?</p>
                <Link to="/register">Crie a sua conta aqui</Link>
              </div>
              
              <span>
                Você também pode preencher os dados para entrar, esses dados não serão salvos no nosso sistema. Na próxima vez você terá que preencher novamente.
              </span>
              
              <div className='login'>
              <form>
                <div className='login-input'>
                    <div >
                      <label htmlFor="email">Email</label>
                      <input
                        placeholder='Digite o email da visita.'
                        type="email"
                        autoCapitalize="none"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  {nomeObr ? (
                    <div>
                      <label htmlFor="nome">Nome</label>
                      <input
                        placeholder='Digite seu nome completoa.'
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </div>
                  ): null}
                  {celObr ? (
                    <div>
                      <label htmlFor="cel">Celular</label>
                      <input
                        placeholder='Digite o seu Celular.'
                        onChange={(e) => setCel(e.target.value)}
                      />
                    </div>
                  ) : null}
                  {cpfObr ? (
                    <div>
                      <label htmlFor="cpf">CPF</label>
                      <input
                        placeholder='Digite o seu CPF.'
                        onChange={(e) => setCpf(e.target.value)}
                      />
                    </div>
                  ) : null}
                  {dataNascimentoObr ? (
                    <div>
                      <label htmlFor="data">Data de nascimento</label>
                      <input
                        placeholder='Digite sua data de nascimento.'
                        onChange={(e) => setDataNascimento(e.target.value)}
                      />
                    </div>
                  ) : null}
                  {cepObr ?  (
                    <div>
                      <label htmlFor="cep">Cep</label>
                      <input
                        placeholder='Digite oseu CEP.'
                        onChange={(e) => setCep(e.target.value)}
                      />
                    </div>
                  ) : null}
                  {endObr ? (
                    <div>
                      <label htmlFor="end">Endereço</label>
                      <input
                        placeholder='Digite o seu endereço.'
                        onChange={(e) => setEnd(e.target.value)}
                      />
                    </div>
                  ) : null}
                  {numDocObr ? (
                    <div>
                      <label htmlFor="tipoDoc">*Tipo de documento</label>
                      <Select
                        value={selectedDoc}
                        onChange={handleSelectDoc}
                        options={tiposDoc}
                        placeholder="Tipo de documento"
                        className="seu-select"
                      />
                      <label htmlFor="doc">Numero do documento </label>
                      <input
                        placeholder='Digite o numero do documento.'
                        onChange={(e) => setNumDoc(e.target.value)}
                      />
                    </div>
                  ) : null}
                  {fotoObr ? (
                    <div>
                      <label htmlFor="foto" className="foto">Selecionar Foto do documento</label>
          
                      <input
                        type="file"
                        name="foto"
                        id="foto"
                        style={{ display: 'none' }}
                        accept='image/*'
                        capture="camera"
                        onChange={handleFileChange}
                      />
                      {foto && (
                        <img key={foto} src={fotoUrl } alt="preview" width="200" />
                      )}
                    </div>
                  ) : null}
                  {generoObr ? (
                    <div>
                      <label htmlFor="genero">Genero</label>
                      <input
                        placeholder='Digite "M" para masculino, "F" para feminino ou "Z" para não informar.'
                        onChange={(e) => setGenero(e.target.value)}
                      />
                    </div>
                  ): null}
                  
                  {profissaoObr ? (
                    <div>
                      <label htmlFor="profissao">Profissão</label>
                      <input
                        placeholder='Digite sua Profissão.'
                        onChange={(e) => setProfissao(e.target.value)}
                      />
                    </div>
                  ) : null}
                  {placaObr ? (
                    <div>
                      <label htmlFor="placa">Placa do carro</label>
                      <input
                        placeholder='Digite a placa do carro.'
                        onChange={(e) => setPlaca(e.target.value)}
                      />
                    </div>
                  ) : null}
                </div>
                <button className='login-button' onClick={handleEntrar}>Fazer check-in</button>
                </form>
              </div>
            </div>
          ) : (
            <div><button className='login-button' onClick={handleEntrar}>Fazer check-in</button></div>
          )}
          
        </div>
      ) : (
        <div>
          <span>Empresa não cadastrada</span>
        </div>
      )}
    </div>
  );
};
