import { useState, useRef, useEffect  } from "react";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { IMaskInput } from "react-imask";
import Select from 'react-select';
import axios from 'axios';

import "./styles.css";

import { toast } from "react-toastify";
import Toast from "../../components/Toast";

import Logo from '../../assets/image/logo.png';

import { doc, setDoc, getDoc } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../service/firebaseConfig';

export function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cel, setCel] = useState('');
  const [selectedUf, setSelectedUf] = useState(null);
  const [selectedCidade, setSelectedCidade] = useState(null);
  const [logradouro , setLogradouro ] = useState(null);
  const [cep, setCep] = useState('');
  const [end, setEnd] = useState('');
  const [tipoDoc, setTipoDoc] = useState('');
  const [numDoc, setNumDoc] = useState('');
  const [orgao, setOrgao] = useState('');
  const [placa, setPlaca] = useState('');
  const [profissao, setProfissao] = useState('');
  const [genero, setGenero] = useState('');
  const [tipoCadastro, setTipoCadastro] = useState(0);
  const [cadastroValidado, setCadastroValidado] = useState(false);
  const [foto, setFoto] = useState(null);
  const [fotoUrl, setFotoUrl] = useState('');
  const [address, setAddress] = useState('');
  const [loadingCep, setLoadingCep] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedGenero, setSelectedGenero] = useState(null);
  const videoRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [preview, setPreview] = useState([]);


  const navigate = useNavigate();

  let validaErro = false
  let downloadURL

  const tiposDoc = [
    { value: 1, label: 'Identidade' },
    { value: 2, label: 'CNH' },
    { value: 3, label: 'Passaporte' },
    { value: 4, label: 'Carteira de trabalho' },
  ];

  const tiposGenero = [
    { value: 1, label: 'Masculino' },
    { value: 2, label: 'Feminino' },
    { value: 3, label: 'Não informar' },
  ];

  const handleSelectDoc = (selectedDoc) => {
    setSelectedDoc(selectedDoc);
    setTipoDoc(selectedDoc.value)
    console.log(selectedDoc)
  };

  const handleSelectGenero = (selectedGenero) => {
    setSelectedGenero(selectedGenero);
    setGenero(selectedGenero.value)
    console.log(selectedGenero)
  };

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  async function handleCadastrar(e) {
    e.preventDefault();
    validar()
    if (!validaErro){
      if (!/\S+@\S+\.\S+/.test(email)) {
        toast.error("Por favor, digite um email válido.");
        return;
      }
  
      // Validação do resumo
      if (password.length < 6) {
        toast.error("senha com ao menos 6 caracteres.");
        return;
      }
      try {
        createUserWithEmailAndPassword(email, password);
      } catch (error) {
        console.log(error)
        toast.error("Erro ao criar usuario.");
      }
      let infoCad

      if (!nome || !cpf || !cel || !dataNascimento ){
        infoCad = 0
      }else{
        if (!genero || !cep || !end || !tipoDoc || !numDoc || !foto || !profissao || !placa){
          infoCad = 1
        }else {
          infoCad = 2
          await uploadImagem()
        }
      }
      if(!cnpj) {
          setCnpj('')
      }

      let cadastro = {
        nome: nome,
        cpf: cpf,
        dataNascimento: dataNascimento,
        cel: cel,
        tipoCadastro: infoCad,
        estado: selectedUf,
        cidade: selectedCidade,
        cep: cep,
        logradouro: logradouro,
        end: end,
        tipoDoc: tipoDoc,
        numDoc: numDoc,
        orgao: orgao,
        placa: placa,
        profissao: profissao,
        genero: genero,
        cadastroValidado: false,
        cnpj: cnpj
      } 

      try {
        await setDoc(doc(db, "usuarios", email), cadastro);
        console.log('certo')
        Toast.success("Usuário cadastrado com sucesso!");
            navigate('/login')
        } catch (error) {
          console.log(error)
          toast.error("Erro ao incluir o cadastro.");
      }
    }
  }

  async function uploadImagem() {
    try {
      /*
        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
              resolve(xhr.response);
            };
            xhr.onerror = function (e) {
              reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", foto, true);
            xhr.send(null);
          });
        const storageRef = ref(storage, `Images/${email}/fotodoc`);
        const result = await uploadBytes(storageRef, blob);
          
        downloadURL = await getDownloadURL(storageRef);
        */
        const storageRef = ref(storage, `Images/${email}/fotodoc`);
        const result = await uploadBytes(storageRef, foto);
    } catch (error) {
        Toast.error({
            type: 'error',
            text1: error,
            text2: 'Erro ao incluir imagem.',
            });
    }
}

  const validar = () => {
    if (nome != '' ){
      if (nome === '' || nome.length < 5){
        toast.error("Informe seu nome completo.");
        validaErro = true
        return !validaErro
      }
    }

    if (cpf != ''){
      if (cpf === '' || cpf.length !== 14){
        toast.error("CPF não informado ou com tamanho inválido.");
        validaErro = true
        return !validaErro
      }

      if (!validarCPF(cpf)) {
        toast.error("CPF inválido.");
        validaErro = true
        return !validaErro
      }
    }

    if (dataNascimento !== ''){
      const dataMoment = moment(dataNascimento, 'YYYY-MM-DD', true);
      const dataValida = dataMoment.isValid();

      if (!dataValida) {
        toast.error("Data de nascimento invalida.");
        validaErro = true
        return !validaErro
      }
    }

    if (cel != ''){
      if (cel === '' || cel.length !== 15){
        toast.error("Telefone invalido.");
        validaErro = true
        return !validaErro
      }
    }

    if (genero != '' ) {
      if (genero === '' ) {
        toast.error("Gênero invalido.");
        validaErro = true
        return !validaErro
      }
    }

    if (cep != '') {
      if (cep === '' || selectedUf === '' ) {
        toast.error("Cep invalido.");
        validaErro = true
        return !validaErro
      }
    
      if (end === '') {
        toast.error("Informe o complemento do endereço.");
        validaErro = true
        return !validaErro
      }
    }

    if (tipoDoc != '') {
      if (numDoc === '') {
        toast.error("Número do documento invalido.");
        validaErro = true
        return !validaErro
      }
    }
  }

  function validarCPF(cpf) {
    cpf = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  
    if (cpf === '' || cpf.length !== 11) {
      // CPF não informado ou com tamanho inválido
      return false;
    }
  
    // Verifica se todos os dígitos são iguais, o que torna o CPF inválido
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
  
    // Validação dos dígitos verificadores
    let soma = 0;
    let resto;
  
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
  
    soma = 0;
  
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseInt(cpf.substring(10, 11))) {
      return false;
    }
  
    return true;
  }

  if (loading) {
    return <p>carregando...</p>;
  }

  const fetchAddress = async () => {
    console.log('cep.length = ', cep.length)
    console.log('cep = ', cep)
    if (cep.length === 8) {
      try {
        setLoadingCep(true);
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { data } = response;

        console.log('data.uf = ', data.uf)
        if (data.uf) {
          console.log('ok')
        setAddress(data.logradouro + ', ' + data.localidade + ' - ' + data.uf);
        setSelectedUf(data.uf)
        setSelectedCidade(data.localidade)
        setLogradouro(data.logradouro)
        }else{
          console.log('erro')
          setSelectedUf('')
          setSelectedCidade('')
          setLogradouro('')
          setAddress('CEP não encontrado');
        }
      } catch (error) {
        setAddress('CEP não encontrado');
      } finally {
        setLoadingCep(false);
      }
    } else {
      setAddress('');
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  };

  const captureImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const capturedImageData = canvas.toDataURL('image/jpeg');
    setImageData(capturedImageData);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    
    if (selectedFile) {
      // Defina a foto em setFoto
      setFoto(selectedFile);
      
      // Gere a URL da foto e defina-a em setFotoUrl
      const imageUrl = URL.createObjectURL(selectedFile);
      setFotoUrl(imageUrl);
    }else{
      setFoto(null)
      setFotoUrl('')
    }
  };

  function handleLogin() {
    navigate('/login')
  }

  return (
    <div className="register">
      <span>Por favor digite suas informações de cadastro</span>
      <h4>Campos obrigatorios</h4>
      <div className="formulario">
        <div className="inputContainer">
          <label htmlFor="email">*E-mail</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Seu E-mail"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="password">*Senha</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="********************"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        </div>
        <h4>Campos opcionais</h4>
        <div className="formulario">
        <div className="inputContainer" autocomplete="off">
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            name="nome"
            id="nome"
            placeholder="Seu nome"
            onChange={(e) => setNome(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cpf">CPF</label>
          <IMaskInput
            type="text"
            id="cpf"
            value={cpf}
            mask="000.000.000-00"
            placeholder="Digite o seu CPF"
            onChange={(e) => setCpf(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="dataNascimento">Data de nascimento</label>
          <input
            type="date"
            name="dataNascimento"
            id="dataNascimento"
            placeholder="Informe a data no formato DD/MM/YYYY"
            onChange={(e) => setDataNascimento(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cel">Celular</label>
          <IMaskInput
            type="cel"
            name="phone"
            id="phone"
            mask="(00) 00000-0000"
            placeholder="(00) 00000-0000"
            value={cel}
            onChange={(e) => setCel(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="genero">Gênero</label>
          <Select
            value={selectedGenero}
            onChange={handleSelectGenero}
            options={tiposGenero}
            placeholder="Gênero"
            className="seu-select"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cep">CEP:</label>
          <input
            type="text"
            value={cep}
            placeholder="Digite o seu CEP"
            onChange={(e) => {setCep(e.target.value);
              fetchAddress();}}
            onBlur={fetchAddress}
            autocomplete="off"
          />
          
        </div>
        {loadingCep ? <p>Buscando endereço...</p> : <p>{address}</p>}
        <div className="inputContainer">
          <label htmlFor="end">*Complemento</label>
          <input
            type="text"
            name="end"
            id="end"
            placeholder="Complemento do endereço"
            onChange={(e) => setEnd(e.target.value)}
          />
          </div>
        <div className="inputContainer">
          <label htmlFor="tipoDoc">Tipo de documento</label>
          <Select
            value={selectedDoc}
            onChange={handleSelectDoc}
            options={tiposDoc}
            placeholder="Tipo de documento"
            className="seu-select"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="numDoc">Numero do documento</label>
          <input
            type="text"
            name="numDoc"
            id="numDoc"
            placeholder="Numero do documento"
            onChange={(e) => setNumDoc(e.target.value)}
          />
        </div>
        {tipoDoc === 1 ? (
          <div className="inputContainer">
            <label htmlFor="orgao">Orgão expeditor</label>
            <input
              type="text"
              name="orgao"
              id="orgao"
              placeholder="Orgão expeditor"
              onChange={(e) => setOrgao(e.target.value)}
            />
          </div>
        ): (
            <div></div>
        )}
        <div className="inputContainer">
        <span>Foto do documento é obrigatoria para alguns estabelecimentos</span>
          <label htmlFor="foto" className="foto">Selecionar Foto do documento</label>
          
          <input
            type="file"
            name="foto"
            id="foto"
            style={{ display: 'none' }}
            accept='image/*'
            capture="camera"
            onChange={handleFileChange}
          />
          {foto && (
            <img key={foto} src={fotoUrl } alt="preview" width="200" />
          )}
        </div>
        <div className="inputContainer">
          <label htmlFor="profissao">Profissão</label>
          <input
            type="text"
            name="profissao"
            id="profissao"
            placeholder="Sua profissao"
            onChange={(e) => setProfissao(e.target.value)}
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="placa">Placa</label>
          <input
            type="text"
            name="placa"
            id="placa"
            placeholder="Placa do seu carro"
            onChange={(e) => setPlaca(e.target.value)}
          />
        </div>
        <button onClick={handleCadastrar} className="button">
          Cadastrar 
        </button>
        <button onClick={handleLogin} className="button">
          Voltar 
        </button>
      </div>
    </div>
  );
}