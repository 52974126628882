// utils.js
import moment from 'moment';
import { toast } from "react-toastify";

export const validarCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

  if (cpf === '' || cpf.length !== 11) {
    return false; // CPF não informado ou com tamanho inválido
  }

  if (/^(\d)\1+$/.test(cpf)) {
    return false; // Verifica se todos os dígitos são iguais, o que torna o CPF inválido
  }

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

export const validar = (dados) => {
    console.log('dados = ', dados)
  let validaErro = false;

  if (!/\S+@\S+\.\S+/.test(dados.email)) {
    toast.error(`Email " ${dados.email} " invalido. `);
    validaErro = true;
  }
  if (dados.nome && dados.nome.length < 5) {
    toast.error(`Informe seu nome completo. para ${dados.nome}`);
    validaErro = true;
  }

  if (dados.cpf && dados.cpf.length !== 14) {
    toast.error(`CPF ${dados.cpf} com tamanho inválido.`);
    validaErro = true;
  }

  if (dados.cpf && !validarCPF(dados.cpf)) {
    toast.error(`CPF  "${dados.cpf}"  inválido.`);
    validaErro = true;
  }

  if (dados.dataNascimento ) {
    const dataMoment = moment(dados.dataNascimento, 'YYYY-MM-DD', true);
    if (!dataMoment.isValid()) {
      toast.error("Data de nascimento inválida.");
      validaErro = true;
    }
  }

  if (dados.cel && dados.cel.length !== 15) {
    toast.error("Telefone inválido.");
    validaErro = true;
  }

  if (dados.cep || dados.selectedUf ) {
    toast.error("Cep inválido.");
    validaErro = true;
  }

  if (dados.tipoDoc  && dados.numDoc ) {
    toast.error("Número do documento inválido.");
    validaErro = true;
  }

  return !validaErro; // Retorna true se não houver erros
};
