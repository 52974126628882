import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { useNavigate} from "react-router-dom";
import { IMaskInput } from "react-imask";
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../service/firebaseConfig';

import { AuthContext } from '../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import Toast from "../../components/Toast";
import { doc, setDoc, getDoc, updateDoc, collection } from "firebase/firestore";

import './styles.css';

export const MenuAdmin = () => {
  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const [emailUsu, setEmailUsu] = useState('');
  const [cpf, setCpf] = useState(0);
  const [end, setEnd] = useState('');
  const [tipoCadastro, setTipoCadastro] = useState(0);
  const [cadastroValidado, setCadastroValidado] = useState(false);
  const [nomeUsu, setNomeUsu] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [numUnidades, setNumUnidades] = useState('');
  const [emailResp, setEmailResp] = useState('');
  const [tipoEmpresa, setTipoEmpresa] = useState('');
  const [entradaAutomatica, setEntradaAutomatica] = useState(false);
  const [entradaPorteiro, setEntradaPorteiro] = useState(false);
  const [atualizar, setAtualizar] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateNum, setSelectedStateNum] = useState(0);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityNum, setSelectedCitynum] = useState(0);

  const [nome, setNome] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cel, setCel] = useState('');
  const [selectedUf, setSelectedUf] = useState(null);
  const [selectedCidade, setSelectedCidade] = useState(null);
  const [cep, setCep] = useState('');
  const [tipoDoc, setTipoDoc] = useState('');
  const [numDoc, setNumDoc] = useState('');
  const [orgao, setOrgao] = useState('');
  const [placa, setPlaca] = useState('');
  const [profissao, setProfissao] = useState('');
  const [genero, setGenero] = useState('');

  const navigate = useNavigate();

  const [celCheck, setCelCheck] = useState(false);
  const [cepCheck, setCepCheck] = useState(false);
  const [cpfCheck, setCpfCheck] = useState(false);
  const [dataNascimentoCheck, setDataNascimentoCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(true);
  const [endCheck, setEndCheck] = useState(false);
  const [fotoCheck, setFotoCheck] = useState(false);
  const [generoCheck, setGeneroCheck] = useState(false);
  const [nomeCheck, setNomeCheck] = useState(false);
  const [numDocCheck, setNumDocCheck] = useState(false);
  const [profissaoCheck, setProfissaoCheck] = useState(false);
  const [placaCheck, setPlacaCheck] = useState(false);


  useEffect(() => {
    // Busca os estados da API do IBGE
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados/?orderBy=nome")
      .then((response) => response.json())
      .then((data) => setStates(data));
  }, []);

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    const stateNome = event.target.options[event.target.selectedIndex].text;
    setSelectedState(stateNome);
    setSelectedStateNum(stateId);

    // Busca as cidades do estado selecionado na API do IBGE
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios?orderBy=nome`
    )
      .then((response) => response.json())
      .then((data) => setCities(data));
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    const cityNome = event.target.options[event.target.selectedIndex].text;
    setSelectedCitynum(cityId);
    setSelectedCity(cityNome);

  }; 

  async function incluirContratante() {
    const numeroscnpj = cnpj.replace(/[^0-9]/g, '');

    try {
      let camposObrigatoriosInc = {
        cel: celCheck,
        cep: cepCheck,
        cpf: cpfCheck,
        dataNascimento: dataNascimentoCheck,
        email: emailCheck,
        end: endCheck,
        foto: fotoCheck,
        genero: generoCheck,
        nome: nomeCheck,
        numDoc: numDocCheck,
        profissao: profissaoCheck,
        placa: placaCheck
      }

      let cadastro = {
        contratante: cnpj,
        nome: nomeFantasia,
        tipoEmpresa: tipoEmpresa,
        emailResp: emailResp,
        unidade: numUnidades,
        entradaAutomatica: entradaAutomatica,
        entradaPorteiro: entradaPorteiro,
        estado: selectedState,
        nomeMunicipio: selectedCity,
        codigoMunicipio: parseInt(selectedCityNum),
        ativo: true,
        camposObrigatorios: camposObrigatoriosInc
      } 
      await setDoc(doc(db, "contratantes", numeroscnpj), cadastro);

      const donoTab = collection(db, 'contratantes', numeroscnpj, 'dono');
      const incluirDonoTab = doc(donoTab, emailResp)

      let tabDonoData = {
          email: emailResp, // Email
      };

      await setDoc(incluirDonoTab, tabDonoData);

      const usuarioTab = collection(db, 'usuarios', emailResp, 'dono');
      const incluirUsuarioTab = doc(usuarioTab, numeroscnpj)

      let tabUsuarioData = {
        ativo: true
      };

      await setDoc(incluirUsuarioTab, tabUsuarioData)
      Toast.success('Dono incluido com sucesso.');
      
    } catch (error) {
      console.log('erro = ', error)
      toast.error('Erro ao incluir dono.');
    }
  }

  

  const handleCnpjChange = (e) => {
    // O valor sem máscara está disponível em e.target.value
    setCnpj(e.target.value);
  };

  return (
    <div className="MenuAdmin">
      <h1>Cadastrar empresa</h1>
      <div className="selecao">
        <label>
        Estado:
        <select value={selectedStateNum} onChange={(event) => handleStateChange(event)}>
          <option value="">Selecione o estado</option>
          {states.map((state) => (
            <option key={state.id} value={state.id}>
              {state.nome}
            </option>
          ))}
        </select>
      </label>

      {selectedState && (
        <label>
          Município:
          <select value={selectedCityNum} onChange={handleCityChange}>
            <option value="">Selecione o município</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.nome}
              </option>
            ))}
          </select>
        </label>
      )}
      </div>
      <label>CNPJ empresa contratante</label>
      <IMaskInput
        mask="00.000.000/0000-00"
        maskChar=""
        value={cnpj || ""}
        onChange={handleCnpjChange}
        placeholder="Digite o CNPJ"
      />
      <label>Nome fantasia</label>
      <input
        
        placeholder="digite o nome fantasia"
        value={nomeFantasia}
        onChange={(e) => setNomeFantasia(e.target.value)}
      />
      <label>Quantidade de unidades</label>
      <input
        
        placeholder="digite o numero de unidades"
        value={numUnidades}
        onChange={(e) => setNumUnidades(parseInt(e.target.value))}
        type="number"
      />
      <label>Email do responsavel</label>
      <input
        
        placeholder="digite o email"
        value={emailResp}
        onChange={(e) => setEmailResp(e.target.value)}
        autoCapitalize="none"
        type="email"
      />
      <label>Tipo de empresa</label>
      <form className='escolha'>
        <div className='teste'>
          <input type="radio" name="tipoEmpresa" value="Bar" onClick={(e) => setTipoEmpresa(e.target.value)} />
          <label> Bar </label>
        </div>
        <div className='teste'>
          <input type="radio" name="tipoEmpresa" value="Boate" onClick={(e) => setTipoEmpresa(e.target.value)} />
          <label> Boate</label>
        </div>
        <div className='teste'>
          <input type="radio" name="tipoEmpresa" value="Condominio residencial" onClick={(e) => setTipoEmpresa(e.target.value)} />
          <label> Condomínio Residencial </label>
        </div>
        <div className='teste'>
          <input type="radio" name="tipoEmpresa" value="Condominio Empresarial" onClick={(e) => setTipoEmpresa(e.target.value)} />
          <label> Condomínio Empresarial</label>
        </div>
        <div className='teste'>
          <input type="radio" name="tipoEmpresa" value="Hotel" onClick={(e) => setTipoEmpresa(e.target.value)} />
          <label> Hotel</label>
        </div>
        <div className='teste'>
          <input type="radio" name="tipoEmpresa" value="Controle interno" onClick={(e) => setTipoEmpresa(e.target.value)} />
          <label>Controle interno</label>
        </div>
      </form>
      <h4>Dados obrigatorios para entrada</h4>
      <div className='checkbox'>
        <div className='checkbox-item'>
          <label htmlFor="celCheck">Celular</label>
          <input type="checkbox" id="celCheck" checked={celCheck} onChange={() => setCelCheck(!celCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="cepCheck">Cep</label>
          <input type="checkbox" id="cepCheck" checked={cepCheck} onChange={() => setCepCheck(!cepCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="cpfCheck">CPF</label>
          <input type="checkbox" id="cpfCheck" checked={cpfCheck} onChange={() => setCpfCheck(!cpfCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="dataNascimentoCheck">Data de nascimento</label>
          <input type="checkbox" id="dataNascimentoCheck" checked={dataNascimentoCheck} onChange={() => setDataNascimentoCheck(!dataNascimentoCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="endCheck">Endereço</label>
          <input type="checkbox" id="endCheck" checked={endCheck} onChange={() => setEndCheck(!endCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="fotoCheck">Foto</label>
          <input type="checkbox" id="fotoCheck" checked={fotoCheck} onChange={() => setFotoCheck(!fotoCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="generoCheck">Genero</label>
          <input type="checkbox" id="generoCheck" checked={generoCheck} onChange={() => setGeneroCheck(!generoCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="nomeCheck">Nome</label>
          <input type="checkbox" id="nomeCheck" checked={nomeCheck} onChange={() => setNomeCheck(!nomeCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="numDocCheck">Numero documento</label>
          <input type="checkbox" id="numDocCheck" checked={numDocCheck} onChange={() => setNumDocCheck(!numDocCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="profissaoCheck">Profissão</label>
          <input type="checkbox" id="profissaoCheck" checked={profissaoCheck} onChange={() => setProfissaoCheck(!profissaoCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="placaCheck">Placa</label>
          <input type="checkbox" id="placaCheck" checked={placaCheck} onChange={() => setPlacaCheck(!placaCheck)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="entradaAutomatica">Entrada Automatica</label>
          <input type="checkbox" id="entradaAutomatica" checked={entradaAutomatica} onChange={() => setEntradaAutomatica(!entradaAutomatica)} />
          
        </div>
        <div className='checkbox-item'>
          <label htmlFor="entradaPorteiro">Entrada com porteiro</label>
          <input type="checkbox" id="entradaPorteiro" checked={entradaPorteiro} onChange={() => setEntradaPorteiro(!entradaPorteiro)} />
          
        </div>
      </div>
      <button className="home-button" onClick={incluirContratante}>
        Incluir contratante
      </button>
    </div>
  )
};
