import { useState, useRef, useEffect, useContext  } from "react";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { IMaskInput } from "react-imask";
import Select from 'react-select';
import axios from 'axios';

import "./styles.css";

import { toast } from "react-toastify";
import Toast from "../../components/Toast";

import Logo from '../../assets/image/logo.png';

import { doc, setDoc, getDoc } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../service/firebaseConfig';

import { AuthContext } from '../../routes/contexts/AuthContext';

export function Atualizar() {

  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const [password, setPassword] = useState("");
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cel, setCel] = useState('');
  const [selectedUf, setSelectedUf] = useState(null);
  const [selectedCidade, setSelectedCidade] = useState(null);
  const [logradouro , setLogradouro ] = useState(null);
  const [cep, setCep] = useState('');
  const [end, setEnd] = useState('');
  const [tipoDoc, setTipoDoc] = useState('');
  const [numDoc, setNumDoc] = useState('');
  const [orgao, setOrgao] = useState('');
  const [placa, setPlaca] = useState('');
  const [profissao, setProfissao] = useState('');
  const [genero, setGenero] = useState('');
  const [tipoCadastro, setTipoCadastro] = useState(0);
  const [cadastroValidado, setCadastroValidado] = useState(false);
  const [foto, setFoto] = useState(null);
  const [fotoUrl, setFotoUrl] = useState('');
  const [address, setAddress] = useState('');
  const [loadingCep, setLoadingCep] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedGenero, setSelectedGenero] = useState(null);
  const videoRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [preview, setPreview] = useState([]);
  const [generoAtual, setGeneroAtual] = useState('')
  const [tipoDocAtual, setTipoDocAtual] = useState('');



  const navigate = useNavigate();

  let validaErro = false
  let dadosUsu = {}
  let downloadURL

  useEffect(() => {
    async function usuario() {
      await buscaDados();
    }
    usuario();
  }, []);

  async function buscaDados(){
    const docRef = doc(db, 'usuarios', email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      dadosUsu = docSnap.data()
      setNome(docSnap.data().nome)
      setCpf(docSnap.data().cpf)
      setDataNascimento(docSnap.data().dataNascimento)
      setCel(docSnap.data().cel)
      setGenero(docSnap.data().genero)
      setCep(docSnap.data().cep)
      setEnd(docSnap.data().end)
      setTipoDoc(docSnap.data().tipoDoc)
      setNumDoc(docSnap.data().numDoc)
      setOrgao(docSnap.data().orgao)
      setProfissao(docSnap.data().profissao)
      setPlaca(docSnap.data().placa)
      fetchAddress();
      const generoSelecionado = tiposGenero.find((item) => item.value === docSnap.data().genero);
      setGeneroAtual(generoSelecionado ? generoSelecionado.label : 'Não informado')
      const tipoDocSelecionado = tiposDoc.find((item) => item.value === docSnap.data().tipoDoc);
      setTipoDocAtual(tipoDocSelecionado ? tipoDocSelecionado.label : 'Não informado')
      buscarImagem();

    }
  }

  async function buscarImagem() {
    try {
      const storageRef = ref(storage, `Images/${email}/fotodoc`);
      const imageUrl = await getDownloadURL(storageRef);
      
      // Agora você tem a URL de download da imagem
      setFoto(imageUrl)
      setFotoUrl(imageUrl)
  
      // Faça o que quiser com a URL, como exibir a imagem em uma tag <img>
    } catch (error) {
      console.log("Erro ao buscar imagem.");
    }
  }

  const tiposDoc = [
    { value: 1, label: 'Identidade' },
    { value: 2, label: 'CNH' },
    { value: 3, label: 'Passaporte' },
    { value: 4, label: 'Carteira de trabalho' },
  ];

  const tiposGenero = [
    { value: 1, label: 'Masculino' },
    { value: 2, label: 'Feminino' },
    { value: 3, label: 'Não informar' },
  ];

  const handleSelectDoc = (selectedDoc) => {
    setSelectedDoc(selectedDoc);
    setTipoDoc(selectedDoc.value)
  };

  const handleSelectGenero = (selectedGenero) => {
    setSelectedGenero(selectedGenero);
    setGenero(selectedGenero.value)
  };

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  async function handleCadastrar(e) {
    e.preventDefault();
    validar()
    if (!validaErro){
      let infoCad
            if (!foto) {
                infoCad = 1
            }else{
                infoCad = 2
                await uploadImagem()
            }
            if(!cnpj) {
                setCnpj('')
            }

            let cadastro = {
             nome: nome,
             cpf: cpf,
             dataNascimento: dataNascimento,
             cel: cel,
             tipoCadastro: infoCad,
             estado: selectedUf,
             cidade: selectedCidade,
             cep: cep,
             logradouro: logradouro,
             end: end,
             tipoDoc: tipoDoc,
             numDoc: numDoc,
             orgao: orgao,
             placa: placa,
             profissao: profissao,
             genero: genero,
             cadastroValidado: false,
             cnpj: cnpj
           } 
           try {
            await setDoc(doc(db, "usuarios", email), cadastro);
            toast.success("Usuário cadastrado com sucesso!");
                navigate('/login')
            } catch (error) {
              console.log(error)
              toast.error("Erro ao incluir o cadastro.");
            }
    }
  }

  async function uploadImagem() {
    try {
        const storageRef = ref(storage, `Images/${email}/fotodoc`);
        const result = await uploadBytes(storageRef, foto);
    } catch (error) {
        toast.error({
            type: 'error',
            text1: error,
            text2: 'Erro ao incluir imagem.',
            });
    }
  }

  const validar = () => {
    if (nome !== '' && nome.length < 5){
      toast.error("Informe seu nome completo.");
      validaErro = true
      return !validaErro
    }
    
    if (cpf !== '' && cpf.length !== 14){
      toast.error("CPF não informado ou com tamanho inválido.");
      validaErro = true
      return !validaErro
    }

    if (cpf !== ''){
      if (!validarCPF(cpf)) {
        toast.error("CPF inválido.");
        validaErro = true
        return !validaErro
      }
    }

    if (dataNascimento !== ''){
      const dataMoment = moment(dataNascimento, 'YYYY-MM-DD', true);
      const dataValida = dataMoment.isValid();

      if (!dataValida) {
        toast.error("Data de nascimento invalida.");
        validaErro = true
        return !validaErro
      }
    }

    if (cel !== '' && cel.length !== 15){
      toast.error("Telefone invalido.");
      validaErro = true
      return !validaErro
    }

    if (cep !== '' || selectedUf === '' ) {
      toast.error("Cep invalido.");
      validaErro = true
      return !validaErro
    }

    if (tipoDoc !== '') {
      if (numDoc === '') {
        toast.error("Número do documento invalido.");
        validaErro = true
        return !validaErro
      }
    }
  }

  function validarCPF(cpf) {
    cpf = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  
    if (cpf === '' || cpf.length !== 11) {
      // CPF não informado ou com tamanho inválido
      return false;
    }
  
    // Verifica se todos os dígitos são iguais, o que torna o CPF inválido
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
  
    // Validação dos dígitos verificadores
    let soma = 0;
    let resto;
  
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
  
    soma = 0;
  
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseInt(cpf.substring(10, 11))) {
      return false;
    }
  
    return true;
  }

  if (loading) {
    return <p>carregando...</p>;
  }

  const fetchAddress = async () => {
    if (cep.length === 8) {
      try {
        setLoadingCep(true);
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { data } = response;

        if (data.uf) {
        setAddress(data.logradouro + ', ' + data.localidade + ' - ' + data.uf);
        setSelectedUf(data.uf)
        setSelectedCidade(data.localidade)
        setLogradouro(data.logradouro)
        }else{
          setSelectedUf('')
          setSelectedCidade('')
          setLogradouro('')
          setAddress('CEP não encontrado');
        }
      } catch (error) {
        setAddress('CEP não encontrado');
      } finally {
        setLoadingCep(false);
      }
    } else {
      setAddress('');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    
    if (selectedFile) {
      // Defina a foto em setFoto
      setFoto(selectedFile);
      
      // Gere a URL da foto e defina-a em setFotoUrl
      const imageUrl = URL.createObjectURL(selectedFile);
      setFotoUrl(imageUrl);
    }else{
      setFoto(null)
      setFotoUrl('')
    }
  };

  function handleLogin() {
    navigate('/login')
  }

  return (
    <div className="register">
      <span>Por favor digite suas informações de cadastro {dadosUsu.nome}</span>
      <div className="formulario">
        <div className="inputContainer" autocomplete="off">
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            name="nome"
            id="nome"
            value={nome}
            placeholder="Digite o seu nome"
            onChange={(e) => setNome(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cpf">CPF</label>
          <IMaskInput
            id="cpf"
            value={cpf || ""}
            mask="000.000.000-00"
            placeholder="Digite o seu CPF"
            onChange={(e) => setCpf(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="dataNascimento">Data de nascimento</label>
          <input
            type="date"
            name="dataNascimento"
            id="dataNascimento"
            value={dataNascimento}
            placeholder="Informe a data no formato DD/MM/YYYY"
            onChange={(e) => setDataNascimento(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cel">Celular</label>
          <IMaskInput
            name="phone"
            id="phone"
            mask="(00) 00000-0000"
            placeholder="(00) 00000-0000"
            value={cel || ""}
            onChange={(e) => setCel(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="genero">Gênero atual - {generoAtual}</label>
          <Select
            value={selectedGenero}
            onChange={handleSelectGenero}
            options={tiposGenero}
            placeholder="Gênero"
            className="seu-select"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cep">CEP:</label>
          <input
            type="text"
            value={cep}
            placeholder="Digite o seu CEP"
            onChange={(e) => {setCep(e.target.value);
              fetchAddress();}}
            onBlur={fetchAddress}
            autocomplete="off"
          />
          
        </div>
        {loadingCep ? <p>Buscando endereço...</p> : <p>{address}</p>}
        <div className="inputContainer">
          <label htmlFor="end">Complemento</label>
          <input
            type="text"
            name="end"
            id="end"
            value={end}
            placeholder="Complemento do endereço"
            onChange={(e) => setEnd(e.target.value)}
          />
          </div>
        <div className="inputContainer">
          <label htmlFor="tipoDoc">Tipo de documento atual - {tipoDocAtual}</label>
          <Select
            value={selectedDoc}
            onChange={handleSelectDoc}
            options={tiposDoc}
            placeholder="Tipo de documento"
            className="seu-select"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="numDoc">Numero do documento</label>
          <input
            type="text"
            name="numDoc"
            id="numDoc"
            value={numDoc}
            placeholder="Numero do documento"
            onChange={(e) => setNumDoc(e.target.value)}
          />
        </div>
        {tipoDoc === 1 ? (
          <div className="inputContainer">
            <label htmlFor="orgao">Orgão expeditor</label>
            <input
              type="text"
              name="orgao"
              id="orgao"
              value={orgao}
              placeholder="Orgão expeditor"
              onChange={(e) => setOrgao(e.target.value)}
            />
          </div>
        ): (
            <div></div>
        )}
        <div className="inputContainer">
        <span>Foto do documento é obrigatoria para alguns estabelecimentos</span>
          <label htmlFor="foto" className="foto">Selecionar Foto do documento</label>
          
          <input
            type="file"
            name="foto"
            id="foto"
            style={{ display: 'none' }}
            accept='image/*'
            capture="camera"
            onChange={handleFileChange}
          />
          {foto && (
            <img key={foto} src={fotoUrl } alt="preview" width="200" />
          )}
        </div>
        <div className="inputContainer">
          <label htmlFor="profissao">Profissão</label>
          <input
            type="text"
            name="profissao"
            id="profissao"
            value={profissao}
            placeholder="Sua profissao"
            onChange={(e) => setProfissao(e.target.value)}
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="placa">Placa</label>
          <input
            type="text"
            name="placa"
            id="placa"
            value={placa}
            placeholder="Placa do seu carro"
            onChange={(e) => setPlaca(e.target.value)}
          />
        </div>

        <button onClick={handleCadastrar} className="button">
          Salvar 
        </button>
        <button onClick={handleLogin} className="button">
          Voltar 
        </button>
      </div>
    </div>
  );
}