import React, { useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { FaUser } from 'react-icons/fa';

import Logo from '../../assets/image/logo.png';
import { AuthContext } from '../../routes/contexts/AuthContext';
import  {auth} from "../../service/firebaseConfig";
import { signOut } from 'firebase/auth';

import { toast } from "react-toastify";
import Toast from "..//Toast";


import './styles.css'

export const Header = () => {
  const navigate = useNavigate();

  console.log(AuthContext)
  const { currentUser } = useContext(AuthContext);

  function handleLogout() {
    signOut(auth).then(() => {
      Toast.success("Logout efetuado volte sempre!");

      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <div className='header'>
      <div className='header-logo'>
        {!currentUser ? (
          <Link to='/'>
            <img src={Logo} alt='Logo'></img>
          </Link>
        ):(
          <div>
            <Link to='/home'>
              <img src={Logo} alt='Logo'></img>
            </Link>
          </div>
        )}
      </div>
      <div className='header-email'>
        {currentUser && (
            <span>{currentUser.email}</span>
        )}
      </div>
      <div className='header-botao'>
        {currentUser && (
      
          <nav>
            <ul>
              <li><a href="#"><FaUser style={{color: '#80d8e4'}} /></a>
              {/* Menu específico para os emails selecionados */}
              
                <ul className="dropdown-menu">
                  <li><span><a href="/atualizar">Meu cadastro</a></span></li>
                  <li><span><a href="/checkin">Meus Check-in</a></span></li>
                  <li><span><a href="/atualizarsenha">atualizar Senha</a></span></li>
                  <li><span><a href="/login" onClick={handleLogout}>Sair</a></span></li>
                  {currentUser && (currentUser.email === 'hesleicosta@gmail.com' || currentUser.email === 'cleber.digilab@hormail.com') && (
                  <li><a href="#">Opção 3</a></li>
                  )}
                  
                </ul>
              
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  )

}