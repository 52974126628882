import React, { useState, useEffect, useContext, useRef } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { FaTrash, FaPlus } from 'react-icons/fa';
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../service/firebaseConfig';
import { format, toZonedTime  } from 'date-fns-tz';
import { IMaskInput } from "react-imask";

import { AuthContext } from '../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import Toast from "../../components/Toast";
import { doc, setDoc, getDoc, getDocs, collection, deleteDoc,  where, query, onSnapshot, updateDoc } from "firebase/firestore";

import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import Logo from '../../assets/image/logo.png';
import Select from 'react-select';
//import downloadIcon from './download.png';
//import QRCodeGenerator from './QRCodeGenerator';

import './styles.css';

export const MenuPorteiro = () => {

    const { currentUser } = useContext(AuthContext);
    const email = currentUser?.email;
    const location = useLocation();
    const cnpj = location.state ? location.state.cnpj : null;
    const [maisDe1, setMaisDe1] = useState(false);
    const [empresa, setEmpresa] = useState([]);
    const [selectedCnpj, setSelectedCnpj] = useState(null);
    const [nomeEmpresa, setNomeEmpresa] = useState([]);
    const [pedido, setPedido] = useState(false);
    const [idPedido, setIdPedido] = useState(false);
    const [preAutorizado, setPreAutorizado] = useState(false);
    const [nomeMorador, setNomeMorador] = useState('');
    const [endMorador, setEndMorador] = useState('');
    const [data, setData] = useState('');
    const [dataHora, setDataHora] = useState('');
    const [emailVisita, setEmailVisita] = useState('');
    const [tipoEmpresa, setTipoEmpresa] =useState('');
    const [entrada, setEntrada] = useState(null);
    const [entradaPorteiro, setEntradaPorteiro] = useState(false);
    const [camposObrigatorios, setCamposObrigatorios] = useState([]);
    const [cpf, setCpf] = useState(0);
    const [atualizar, setAtualizar] = useState(false);
    const [funcionariosCadastrados, setFuncionariosCadastrados] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
  

    const [dataNascimento, setDataNascimento] = useState('');
    const [cel, setCel] = useState('');
    const [uf, setUf] = useState(null);
    const [cidade, setCidade] = useState(null);
    const [cep, setCep] = useState('');
    const [end, setEnd] = useState('');
    const [selectedGenero, setSelectedGenero] = useState(null);
    const [selectedRefeicao, setSelectedRefeicao] = useState(null);
    const [tipoDoc, setTipoDoc] = useState('');
    const [numDoc, setNumDoc] = useState('');
    const [orgao, setOrgao] = useState('');
    const [placa, setPlaca] = useState('');
    const [profissao, setProfissao] = useState('');
    const [genero, setGenero] = useState('');
    const [refeicao, setRefeicao] = useState('');
    const [tipoCadastro, setTipoCadastro] = useState(0);
    const [nome, setNome] = useState('');
    
    const tiposGenero = [
        { value: 1, label: 'Masculino' },
        { value: 2, label: 'Feminino' },
        { value: 3, label: 'Não informar' },
    ];

    const Tiposrefeicao = [
        { value: 1, label: 'Almoço' },
        { value: 2, label: 'Lanche' },
        { value: 3, label: 'Jantar' },
        { value: 4, label: 'Ceia' },
    ];

    let validaErro = false

    useEffect(()=> {
        const currentDate = new Date();
        const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
        const localDate = new Date(currentDate - timezoneOffset);
        const formattedDate = localDate.toISOString().slice(0, 10);
        const horaVisita = localDate.toString().slice(11, 19);
        if (horaVisita >= '10:00:00' && horaVisita <= '14:00:00'){
            setSelectedRefeicao('Almoço')
        }
        if (horaVisita >= '14:30:00' && horaVisita <= '17:00:00'){
            setSelectedRefeicao('Lanche')
        }
        if (horaVisita >= '17:01:00' && horaVisita <= '21:00:00'){
            setSelectedRefeicao('Jantar')
        }
        if (horaVisita >= '21:01:00' && horaVisita <= '23:00:00'){
            setSelectedRefeicao('Ceia')
        }
        setData(formattedDate);
        async function buscaEmpresas() {
            await buscaDadosEmpresas();
        }
        buscaEmpresas();
      
    },[atualizar]);

    useEffect(() => {
        const currentDate = new Date();
        const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
        const localDate = new Date(currentDate - timezoneOffset);
        const formattedDate = localDate.toISOString().slice(0, 10);
        setData(formattedDate);
        const q = query(collection(db, 'pedidos'), 
            where('cnpj', '==', cnpj),
            where('autorizado', '==', false),
            where('data', '==', formattedDate)
        );
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const pedidos = [];
            
            querySnapshot.forEach((doc) => {
                const pedidoData = doc.data();
                const pedido = {
                    id: doc.id,
                    cel: pedidoData.cel,
                    cep: pedidoData.cep,
                    cidade: pedidoData.cidade,
                    cpf: pedidoData.cpf,
                    dataNascimento: pedidoData.dataNascimento,
                    emailVisita: pedidoData.email,
                    emailMorador: pedidoData.emailMorador,
                    end: pedidoData.end,
                    genero: pedidoData.genero,
                    nome: pedidoData.nome,
                    numDoc: pedidoData.numDoc,
                    placa: pedidoData.placa,
                    profissao: pedidoData.profissao,
                    tipoDoc: pedidoData.tipoDoc,
                    uf: pedidoData.uf
                    // Adicione outras propriedades que deseja armazenar
                };
                pedidos.push(pedido);
                toast.info(`Nova solicitação de check-in de ${pedidoData.email}`);
            });
            setEntrada(pedidos)
        });

        return () => {
            unsubscribe(); // Unsubscribe the listener when component unmounts
        };
    }, [ atualizar]);

    async function buscaDadosEmpresas(){
        try {
            const docRef = doc(db, 'contratantes', cnpj);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setNomeEmpresa(docSnap.data().nome);
                setCamposObrigatorios(docSnap.data().camposObrigatorios);
                setTipoEmpresa(docSnap.data().tipoEmpresa);
                setEntradaPorteiro(docSnap.data().entradaPorteiro);
                buscaDadosFuncionarios();
            } else {
                toast.error('Empresa não cadastrada no check-in Facil.');
            }
        } catch (error) {
            console.error("Erro ao buscar dados da empresa:", error);
        }
            
    }

    async function buscaDadosFuncionarios(){
        const dataAtualBrasilia = obterDataAtualBrasilia();
        const diaSemanaAtual = obterDiaSemana(dataAtualBrasilia);
        try {
            const colectionRef = collection(db, 'contratantes', cnpj, 'funcionario');
            const querySnapshot = await getDocs(colectionRef);
            const funcionarios = [];

        await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const funcionarioData = doc.data();

                const estaDeFolgaHoje = funcionarioData.folga && funcionarioData.folga.includes(diaSemanaAtual);

                // Extrair os campos email, cpf, nome e folga
                if (!estaDeFolgaHoje) {
                    // Extrair os campos email, cpf, nome e folga
                    const funcionario = {
                        email: doc.id, // o ID do documento é o email
                        cpf: funcionarioData.cpf || '', // Pega o campo 'cpf' do documento
                        nome: funcionarioData.nome || '', // Pega o campo 'nome' do documento
                        folga: funcionarioData.folga || [] // Pega o campo 'folga' (array) do documento
                    };

                    const pedidos = await buscaPedido(funcionario.email)
                    const incluir = validaPedido(pedidos, funcionario);
                    // Adiciona o funcionário ao array de funcionários
                    if (incluir) {
                        funcionarios.push(funcionario);
                    }
                }
            })
        );

        funcionarios.sort((a, b) => a.nome.localeCompare(b.nome));
        // Atualiza o estado com o array de funcionários cadastrados
        setFuncionariosCadastrados(funcionarios);
        } catch (error) {
            console.error("Erro ao buscar dados da empresa:", error);
        }
    }

    function validaPedido(pedidos, funcionario) {
        const currentDate = new Date();
        const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
        const localDate = new Date(currentDate - timezoneOffset);
        const formattedDateTime = localDate.toISOString().slice(0, 19);
        const horaVisita = formattedDateTime.toString().slice(11, 19);
    
        // Verifica se existe algum pedido feito pelo funcionário para o mesmo horário
        for (let pedido of pedidos) {
            if (funcionario.email === pedido.email) {
                if (horaVisita >= '10:00:00' && horaVisita <= '14:00:00') {
                    if (pedido.tipoRefeicao === 'Almoço') {
                        return false;
                    }
                }
                if (horaVisita >= '14:30:00' && horaVisita <= '17:00:00') {
                    if (pedido.tipoRefeicao === 'Lanche') {
                        return false;
                    }
                }
                if (horaVisita >= '17:01:00' && horaVisita <= '21:00:00') {
                    if (pedido.tipoRefeicao === 'Jantar') {
                        return false;
                    }
                }
                if (horaVisita >= '21:01:00' && horaVisita <= '23:00:00') {
                    if (pedido.tipoRefeicao === 'Ceia') {
                        return false;
                    }
                }
            }
        }
    
        // Se nenhum pedido corresponder às condições, o funcionário pode ser incluído
        return true;
    }

    async function buscaPedido(email){
        const dataAtualBrasilia = obterDataAtualBrasilia();
        const diaSemanaAtual = obterDiaSemana(dataAtualBrasilia);
        const formattedDateTime = dataAtualBrasilia.toISOString().slice(0, 10);
        const data = dataAtualBrasilia.toISOString().slice(0, 10)

        
        try {
            const pedidos = [];
            const q = query(collection(db, 'contratantes', cnpj, 'pedido'), 
                where('emailVisita', '==', email),
                where('dataVisita', '==', data)
            );

            const querySnapshot = await getDocs(q);
            
        await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const pedidoData = doc.data();
                    // Extrair os campos email, cpf, nome e folga
                    const pedido = {
                        email: pedidoData.emailVisita, // o ID do documento é o email
                        tipoRefeicao: pedidoData.tipoRefeicao || [] // Pega o campo 'folga' (array) do documento
                    };

                    // Adiciona o funcionário ao array de funcionários
                    pedidos.push(pedido);
            })
                
        );

        return pedidos;

        } catch (error) {
            console.error("Erro ao buscar dados da empresa:", error);
        }
            
    }

    async function handleAceitar(emailMorador, emailVisita, id) {
        console.log('aceitar ')
        console.log('emailMorador = ', emailMorador)
        console.log('emailVisita = ', emailVisita)
        console.log('id = ', id)
        validar(emailVisita)
        if (!validaErro){
            const currentDate = new Date();
            const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
            const localDate = new Date(currentDate - timezoneOffset);
            const formattedDateTime = localDate.toISOString().slice(0, 19);
            const docVisita =  cnpj + emailVisita + formattedDateTime;
            const docContratante = emailVisita + formattedDateTime

            // Referência à coleção 'usuarios' e subcoleção 'visitas' para o usuário com emailMorador
            const moradorRef = doc(db, 'usuarios', emailVisita); // Referência ao documento do morador
            const visitasRef = collection(moradorRef, 'pedido'); // Subcoleção 'visitas' dentro de 'usuarios'

            const contratanteRef = doc(db, 'contratantes', cnpj); // Referência ao documento do morador
            const visitasContratanteRef = collection(contratanteRef, 'pedido'); // Subcoleção 'visitas' dentro de 'usuarios'

            let dados = {
                emailVisita: emailVisita,
                dataVisita: formattedDateTime.toString().slice(0,10),
                horaVisita: formattedDateTime.toString().slice(11,19),
                autorizado: true,
                idVisita: id,
                emailPortaria: email,
                emailMorador: emailMorador,
                tipoRefeicao: selectedRefeicao
            }
            try {
                console.log('dados = ', dados)
                await setDoc(doc(visitasRef ), dados);
                await setDoc(doc(visitasContratanteRef), dados);

                if (id != 'automatico') {
                    // Atualizar o campo 'autorizado' para 'true' na coleção de pedidos
                    const pedidoRef = doc(db, 'pedidos', id); // Referência ao documento de pedidos com base no ID
                    await updateDoc(pedidoRef, {
                        autorizado: true
                    });
                }
                setAtualizar(!atualizar)
                toast.success(`Pedido aceito com sucesso para ${emailVisita}`);
            } catch (error) {
                console.error("Erro ao aceitar o pedido:", error);
                toast.error("Erro ao aceitar o pedido.");
            }
        }
            // colocar inclusão com a coleção sendo a de usuario e a sub coleção sendo a empresa
            // fica bom para pesquisa por usuario.
            // e incluir tambem numa coleção com a empresa, pois assim fica facil a pesquisa por empresa
    }

    function validar(emailVisita) {
        const funcionario = verificarFuncionario(emailVisita); // Verifica se o funcionário existe
        const estaDeFolga = funcionario ? verificarFolga(funcionario) : false;
    
        if (!funcionario) {
            toast.error('Funcionário não encontrado.');
            validaErro = true
            return !validaErro
        }
    
        if (estaDeFolga) {
            toast.error('Funcionário está de folga hoje.');
            validaErro = true
            return !validaErro
        }

        if (!selectedRefeicao) {
            toast.error('Selecione o tipo de refeição.');
            validaErro = true
            return !validaErro
        }
    
        // Se passou pelas validações, pode seguir
        return true;
    }

    function verificarFuncionario(emailVisita) {
        const funcionarioEncontrado = funcionariosCadastrados.find(funcionario => funcionario.email === emailVisita);

    // Verifica o que foi retornado pelo find
        if (funcionarioEncontrado) {
            return funcionarioEncontrado;
        } else {
            console.log('Nenhum funcionário encontrado com o email:', emailVisita);
        }
        return null;
    }
    
    function verificarFolga(funcionario) {
        const dataAtualBrasilia = obterDataAtualBrasilia();
        const diaSemanaAtual = obterDiaSemana(dataAtualBrasilia); // Obter o dia da semana para o fuso horário correto
      
        if (funcionario.folga.includes(diaSemanaAtual)) {
            console.log(`O funcionário ${funcionario.nome} está de folga hoje (${diaSemanaAtual}).`);
            return true;
        } else {
            console.log(`O funcionário ${funcionario.nome} NÃO está de folga hoje.`);
            return false;
        }
    }

    function obterDiaSemana(data) {
        const timeZone = 'America/Sao_Paulo'; // Define o fuso horário de Brasília
        
        // Converte a data para o fuso horário de Brasília
        const dataBrasilia = toZonedTime(data, timeZone);
        
        const diasDaSemana = [
            'domingo',
            'segunda',
            'terça',
            'quarta',
            'quinta',
            'sexta',
            'sábado'
        ];
        
        const numeroDiaSemana = dataBrasilia.getDay(); // Agora pega o dia no fuso de Brasília
        
        return diasDaSemana[numeroDiaSemana];
    }

    function obterDataAtualBrasilia() {
        const currentDate = new Date();
        const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
        const dataAtual = new Date(currentDate - timezoneOffset);

        return new Date(dataAtual);
    }


    async function handleRecusar(id) {
        try {
            const pedidoRef = doc(db, 'pedidos', id);

            await deleteDoc(pedidoRef);
            toast.success('Pedido recusado.');
        } catch (error) {
            console.error('Erro ao recusar o pedido:', error);
            toast.error('Erro ao recusar o pedido.');
        }
    }

    

    const handleSelectGenero = (selectedGenero) => {
        setSelectedGenero(selectedGenero);
        setGenero(selectedGenero.value)
    };

    const handleSelectRefeicao = (selectedRefeicao) => {
        setSelectedRefeicao(selectedRefeicao.label);
        setRefeicao(selectedRefeicao.value)
    };

    function handleAceitarLista(emailMorador, emailVisita, id) {
        const currentDate = new Date();
        const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
        const localDate = new Date(currentDate - timezoneOffset);
        const formattedDateTime = localDate.toISOString().slice(0, 19);
        const horaVisita = formattedDateTime.toString().slice(11,19)
        console.log('horaVisita =', horaVisita)
        if (horaVisita >= '10:00:00' && horaVisita <= '14:00:00'){
            setSelectedRefeicao('Almoço')
        }
        if (horaVisita >= '14:30:00' && horaVisita <= '17:00:00'){
            setSelectedRefeicao('Lanche')
        }
        if (horaVisita >= '17:01:00' && horaVisita <= '21:00:00'){
            setSelectedRefeicao('Jantar')
        }
        if (horaVisita >= '21:01:00' && horaVisita <= '23:00:00'){
            setSelectedRefeicao('Ceia')
        }
        handleAceitar(emailMorador, emailVisita, 'automatico')
    }
 
  return (
    <div className="home">
        {entrada ? (
            <div>
                {entrada.map((pedido, index) => (
                    <div key={index} className='visitas'>
                        <h4>Pedidos de entrada:</h4>
                        {pedido.emailVisita ? (
                            <span>Email Visita: {pedido.emailVisita}</span>
                        ) : null}
                        {pedido.nome ? (
                            <span>Nome: {pedido.nome}</span>
                        ) : null}
                        {pedido.cpf ? (
                            <span>Cpf: {pedido.cpf}</span>
                        ) : null}
                        {pedido.cel ? (
                            <span>Celular: {pedido.cel}</span>
                        ) : null}
                        {pedido.dataNascimento ? (
                            <span>Data de nascimento: {pedido.dataNascimento}</span>
                        ) : null}
                        {pedido.genero ? (
                            <span>Genero: {pedido.genero}</span>
                        ) : null}
                        {pedido.estado ? (
                            <span>Estado: {pedido.estado}</span>
                        ) : null}
                        {pedido.cidade ? (
                            <span>Cidade: {pedido.cidade}</span>
                        ) : null}
                        {pedido.end ? (
                            <span>Complemento: {pedido.end}</span>
                        ) : null}
                        {pedido.cep ? (
                            <span>Cep: {pedido.cep}</span>
                        ) : null}
                        {pedido.profissao ? (
                            <span>Profissão: {pedido.profissao}</span>
                        ) : null}
                        {pedido.tipoDoc === 1 && <span>Identidade: {pedido.numDoc}</span>}
                        {pedido.tipoDoc === 2 && <span>CNH: {pedido.numDoc}</span>}
                        {pedido.tipoDoc === 3 && <span>Passaporte: {pedido.numDoc}</span>}
                        {pedido.tipoDoc === 4 && <span>Carteira de trabalho: {pedido.numDoc}</span>}
                        {pedido.placa ? (
                            <span>Placa do carro: {pedido.placa}</span>
                        ) : null}
                        {tipoEmpresa === 'Controle interno' ?(
                            <div>
                                <Select
                                    value={selectedRefeicao.label}
                                    onChange={handleSelectRefeicao}
                                    options={Tiposrefeicao}
                                    placeholder="Tipo de refeição"
                                    className="seu-select"
                                />
                            </div>
                        ) : (<div></div>)}
                        {pedido.emailMorador ? (
                            <span>Autorizado por: {pedido.emailMorador}</span>
                        ) : <span>Pedido não autorizado. Entre em contato com o responsavel</span>}
                        <button className='home-button' onClick={(e) => {handleAceitar(pedido.emailMorador, pedido.emailVisita, pedido.id)}}>aceitar</button>
                        <button className='home-button' onClick={(e) => {handleRecusar(pedido.id)}}>recusar</button>
                    </div>
                ))}
            </div>
        ) : (
            <div></div>
        )}
        <div>
            <div className='MenuAdmin'>
                {tipoEmpresa === 'Controle interno' ?(
                    <div >
                        {funcionariosCadastrados? (
                            <div className='autorizar'>
                                <h4>Funcionarios autorizados:</h4>
                                {funcionariosCadastrados.map((funcionario, index) => (
                                    <div key={index} className='lista'>
                                        <span>{funcionario.nome}</span> {/* Acessar a propriedade 'email' */}
                                        <button onClick={(e) => {handleAceitarLista(email, funcionario.email, pedido.id)}}>
                                        Autorizar refeição
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ): (<div></div>)}
                    </div>
                ) : (<div></div>)}
            </div>
            {entradaPorteiro? (
             <div className='MenuAdmin'>
                <h1>Informar pedido manual</h1>
                
                {camposObrigatorios.email ? (
                    <div className='cadastro'>
                        <input
                            type="email"
                            placeholder={`Digite o email`}
                            value={emailVisita || ''}
                            onChange={(e) => setEmailVisita(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.nome ? (
                    <div>
                        <input
                            type="text"
                            placeholder={`Digite o nome`}
                            value={nome || ''}
                            onChange={(e) => setNome(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.cel ? (
                    <div>
                        <input
                            type="number"
                            placeholder={`Digite o celular`}
                            value={cel || ''}
                            onChange={(e) => setCel(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.cpf ? (
                    <div>
                        <IMaskInput
                            id="cpf"
                            value={cpf || ""}
                            mask="000.000.000-00"
                            placeholder="Digite o seu CPF"
                            onChange={(e) => setCpf(e.target.value)}
                            autocomplete="off"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.cep ? (
                    <div>
                        <input
                            type="number"
                            placeholder={`Digite o cep`}
                            value={cep || ''}
                            onChange={(e) => setCep(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.end ? (
                    <div>
                        <input
                            type="text"
                            placeholder={`Digite o endereço`}
                            value={end || ''}
                            onChange={(e) => setEnd(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.dataNascimento ? (
                    <div>
                        <input
                            type="date"
                            placeholder={`Digite a data de nascimento`}
                            value={dataNascimento || ''}
                            onChange={(e) => setDataNascimento(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.numDoc ? (
                    <div>
                        <input
                            type="number"
                            placeholder={`Digite o numero do documento`}
                            value={numDoc || ''}
                            onChange={(e) => setNumDoc(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.genero ? (
                    <div className='selecao'>
                        <Select
                            value={selectedGenero}
                            onChange={handleSelectGenero}
                            options={tiposGenero}
                            placeholder="Gênero"
                            className="seu-select"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.placa ? (
                    <div>
                        <input
                            type="text"
                            placeholder={`Digite a placa`}
                            value={placa || ''}
                            onChange={(e) => setPlaca(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}
                {camposObrigatorios.profissao ? (
                    <div>
                        <input
                            type="text"
                            placeholder={`Digite a profissao`}
                            value={profissao || ''}
                            onChange={(e) => setProfissao(e.target.value)}
                            autoCapitalize="none"
                        />
                    </div>
                ): (<div></div>)}

                {tipoEmpresa === 'Controle interno' ?(
                    <div>
                        <Select
                            value={selectedRefeicao.label}
                            onChange={handleSelectRefeicao}
                            options={Tiposrefeicao}
                            placeholder="Tipo de refeição"
                            className="seu-select"
                        />
                    </div>
                ) : (<div></div>)}
                <button className='home-button' 
                    onClick={(e) => {handleAceitar(email, emailVisita, pedido.id)}}>
                    aceitar
                </button>

             </div>
            ) : (<div></div>)}
                

        </div>  
    </div>
  )
};
