import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { FaTrash, FaPlus } from 'react-icons/fa';
import { IMaskInput } from "react-imask";
import { db, storage, auth } from '../../../service/firebaseConfig';

import { AuthContext } from '../../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import Toast from "../../../components/Toast";
import { doc, setDoc, getDoc, collection, deleteDoc, getDocs, updateDoc } from "firebase/firestore";

import { validar } from '../../../components/utils';


import '../styles.css';

export const ControleInterno = () => {

  const { currentUser } = useContext(AuthContext);
  const emailGerente = currentUser?.email;
  const location = useLocation();
  const cnpj = location.state ? location.state.cnpj : null;
  const [cpf, setCpf] = useState(0);
  const [atualizar, setAtualizar] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [camposObrigatorios, setCamposObrigatorios] = useState([]);
  const [entradaAutomatica, setEntradaAutomatica] = useState(false);
  const [entradaPorteiro, setEntradaPorteiro] = useState(false);
  const [empresaOk, setEmpresaOk] = useState(false);
  const [tipoEmpresa, setTipoEmpresa] =useState('');
  const [folga, setFolga] =useState('');
    
    const [emailFuncionario, setEmailFuncionario] = useState([]);
    const [nomeFuncionario, setNomeFuncionario] = useState([]);
    const [cpfFuncionario, setCpfFuncionario] = useState([]);
    const [folgaSemanal, setFolgaSemanal] = useState([]);
    const [qtdFuncionarios, setQtdFuncionarios] = useState(0);
    const [refeicaoDiariaIn, setRefeicaoDiariaIn] = useState([]);

    
    
    const [funcionariosCadastrados, setFuncionariosCadastrados] = useState(null);
    const [verFuncionariosCadastrados, setVerFuncionariosCadastrados] = useState(false);

    const [qtdFuncionariosCadastrados, setQtdFuncionariosCadastrados] = useState(0);

    const [adicionarFuncionario, setAdicionarFuncionario] = useState(false);
    
    const diasDaSemana = [
        { label: 'Segunda-feira', value: 'segunda' },
        { label: 'Terça-feira', value: 'terça' },
        { label: 'Quarta-feira', value: 'quarta' },
        { label: 'Quinta-feira', value: 'quinta' },
        { label: 'Sexta-feira', value: 'sexta' },
        { label: 'Sábado', value: 'sábado' },
        { label: 'Domingo', value: 'domingo' },
    ];

    const refeicoes = [
        { label: 'Almoço', value: 'almoco' },
        { label: 'Lanche da tarde', value: 'tarde' },
        { label: 'Jantar', value: 'jantar' },
        { label: 'Lanche da noite', value: 'noite' },
    ];

    useEffect(()=> {
        async function buscaPorteiros() {
            await buscaDadosEmpresa();
        }
        buscaPorteiros();
      
    },[atualizar]);

    useEffect(() => {
        if (qtdFuncionarios > 0) {
          // Inicializa um array com as refeições padrão para cada funcionário
          const initialRefeicoes = Array.from({ length: qtdFuncionarios }, () => ['almoco', 'tarde', 'jantar', 'noite']);
          setRefeicaoDiariaIn(initialRefeicoes);
        }
    }, [qtdFuncionarios]);

    async function buscaDadosEmpresa(){
        try {
            const docRef = doc(db, 'contratantes', cnpj);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setNomeEmpresa(docSnap.data().nome);
                setCamposObrigatorios(docSnap.data().camposObrigatorios);
                setEmpresaOk(true);
                setEntradaAutomatica(docSnap.data().entradaAutomatica)
                setEntradaPorteiro(docSnap.data().entradaPorteiro)
                verCamposObrigatorios(docSnap.data().camposObrigatorios);
                setTipoEmpresa(docSnap.data().tipoEmpresa);
                await buscaDadosFuncionarios();
            } else {
                toast.error('Empresa não cadastrada no check-in Facil.');
            }
        } catch (error) {
            toast.error('Erro ao buscar Empresa.');
        }
    }

    async function buscaDadosFuncionarios(){
        try {
            const colectionRef = collection(db, 'contratantes', cnpj, 'funcionario');
            const querySnapshot = await getDocs(colectionRef);
            const funcionarios = [];
            setQtdFuncionariosCadastrados(querySnapshot.docs.length);

        await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const funcionarioData = doc.data();

                
                // Extrair os campos email, cpf, nome e folga
                const funcionario = {
                    email: doc.id, // o ID do documento é o email
                    cpf: funcionarioData.cpf || '', // Pega o campo 'cpf' do documento
                    nome: funcionarioData.nome || '', // Pega o campo 'nome' do documento
                    folga: funcionarioData.folga || [], // Pega o campo 'folga' (array) do documento
                    refeicoes: funcionarioData.refeicoes || [] // Pega o campo 'folga' (array) do documento
                };

                // Adiciona o funcionário ao array de funcionários
                funcionarios.push(funcionario);
            })
        );

        // Atualiza o estado com o array de funcionários cadastrados
        setFuncionariosCadastrados(funcionarios);
        
        } catch (error) {
            console.error("Erro ao buscar dados da empresa:", error);
        }
    }

    function verCamposObrigatorios(campos){
        const camposTrue = Object.keys(campos).filter(
          (campo) => camposObrigatorios[campo]
        );
    }
    
    async function incluirFuncionarios() {
        let valido = true
        await Promise.all(
            emailFuncionario.map(async (email, index) => {
                const dados = {
                    email: emailFuncionario[index],
                    nome: nomeFuncionario[index],
                    cpf: cpfFuncionario[index],

                }
                
                const isValid = validar(dados);

                if (!isValid) {
                // Buscar o cadastro para o email atual
                    valido = false
                }
            })
        );
        if (valido) {


            await Promise.all(
                emailFuncionario.map(async (email, index) => {
                    try {

                    const funcionarioTab = collection(db, 'contratantes', cnpj, 'funcionario');
                    const incluirFuncionarioTab = doc(funcionarioTab, email)

                    let tabFuncionarioData = {
                        email: emailFuncionario[index], // Email
                        nome: nomeFuncionario[index],   // Nome
                        cpf: cpfFuncionario[index],     // CPF
                        folga: folgaSemanal[index],      // Folga
                        refeicoes: refeicaoDiariaIn[index]
                    };

                    await setDoc(incluirFuncionarioTab, tabFuncionarioData);

                    const usuarioTab = collection(db, 'usuarios', emailFuncionario[index], 'funcionario');
                    const incluirUsuarioTab = doc(usuarioTab, cnpj)

                    let tabUsuarioData = {
                        ativo: true
                    };

                    await setDoc(incluirUsuarioTab, tabUsuarioData)
                    Toast.success('Funcionarios incluidos com sucesso.');
                    setAdicionarFuncionario(false);
                    setAtualizar(!atualizar)
                    } catch (error) {
                        console.log('error = ', error)
                        toast.error('Erro ao incluir Funcionarios.');
                    }
                    
                })
            );
        }
    }

    async function removerFuncionario(index) {
        try {
            const emailFuncionario = funcionariosCadastrados[index].email; // Obtém o email do funcionário a ser removido
                
            // Caminho para o documento do funcionário dentro da subcoleção
            const docRef = doc(db, 'contratantes', cnpj, 'funcionario', emailFuncionario);
    
            // Exclui o documento do funcionário
            await deleteDoc(docRef);

            const usuRef = doc(db, 'usuarios', emailFuncionario, 'funcionario', cnpj);

            await deleteDoc(usuRef);
    
            // Remover funcionário da lista exibida no estado
            const novaLista = [...funcionariosCadastrados];
            novaLista.splice(index, 1);
            setFuncionariosCadastrados(novaLista);
    
            // Exibir feedback de sucesso
            Toast.success('Funcionário removido com sucesso!');
        } catch (error) {
            console.error("Erro ao remover funcionário:", error);
            Toast.error('Erro ao remover funcionário.');
        }
    }
    
    function handleAdicionarFuncionario() {
        setAdicionarFuncionario(true);
    }

    function handleFolgaChange(e, index, diaSelecionado) {
        const isChecked = e.target.checked;
        const updatedFolgaSemanal = [...folgaSemanal];
        
        // Verifica se já existe um array para o funcionário atual
        if (!updatedFolgaSemanal[index]) {
            updatedFolgaSemanal[index] = [];
        }
        if (isChecked) {
            // Se o checkbox for marcado e o dia não estiver no array, adiciona o dia
            if (!updatedFolgaSemanal[index].includes(diaSelecionado)) {
                updatedFolgaSemanal[index].push(diaSelecionado);
            }
        } else {
            // Se o checkbox for desmarcado, remove o dia do array
            updatedFolgaSemanal[index] = updatedFolgaSemanal[index].filter(dia => dia !== diaSelecionado);
        }
        
        // Atualiza o estado com o novo array de folgas
        setFolgaSemanal(updatedFolgaSemanal);
    };

    function abrirAlteracaoFolga(index) {

        const novaLista = [...funcionariosCadastrados];
        novaLista[index].mostrarAlterarFolga = true; // Mostra os campos de alteração
        const folgaSalvas = novaLista[index].folga || []; // Obtém as refeições atuais
        const updatedFolgaDiariaIn = [...folgaSemanal];
        updatedFolgaDiariaIn[index] = folgaSalvas; 
        setFolgaSemanal(updatedFolgaDiariaIn) // Preenche com as folgas atuais
        setFuncionariosCadastrados(novaLista);
    }
    
    // Função para salvar a nova folga
    async function salvarNovaFolga(index) {
        try {
            const funcionario = funcionariosCadastrados[index];
            
            // Usa o array atualizado com os checkboxes
            const novaFolgaArray = folgaSemanal[index] || []; // Verifica se existem novas seleções
            
            // Caminho para o documento do funcionário na subcoleção
            const docRef = doc(db, 'contratantes', cnpj, 'funcionario', funcionario.email);
    
            // Atualiza os dias de folga no Firebase, sobrescrevendo os antigos
            await updateDoc(docRef, { folga: novaFolgaArray });
    
            // Atualiza o estado local, substituindo a folga atual pela nova lista de folgas
            const novaLista = [...funcionariosCadastrados];
            novaLista[index].folga = novaFolgaArray; // Atualiza o estado da folga com a nova lista
            novaLista[index].mostrarAlterarFolga = false; // Fecha o campo de alteração
            setFuncionariosCadastrados(novaLista);
    
            Toast.success('Folga alterada com sucesso!');
        } catch (error) {
            console.error("Erro ao salvar folga:", error);
            Toast.error('Erro ao salvar folga.');
        }
    }
    
    // Função para cancelar a alteração de folga
    function cancelarAlteracaoFolga(index) {
        const novaLista = [...funcionariosCadastrados];
        novaLista[index].mostrarAlterarFolga = false; // Oculta os campos
        setFuncionariosCadastrados(novaLista);
    }

    const handleRefeicaoChange = (e, index, refeicaoValue) => {
        const isChecked = e.target.checked;
        const updatedRefeicaoDiaria = [...refeicaoDiariaIn];
        
        // Verifica se já existe um array para o funcionário atual
        if (!updatedRefeicaoDiaria[index]) {
            updatedRefeicaoDiaria[index] = [];
        }
        if (isChecked) {
            // Se o checkbox for marcado e o dia não estiver no array, adiciona o dia
            if (!updatedRefeicaoDiaria[index].includes(refeicaoValue)) {
                updatedRefeicaoDiaria[index].push(refeicaoValue);
            }
        } else {
            // Se o checkbox for desmarcado, remove o dia do array
            updatedRefeicaoDiaria[index] = updatedRefeicaoDiaria[index].filter(dia => dia !== refeicaoValue);
        }

        setRefeicaoDiariaIn(updatedRefeicaoDiaria)
        
    };

    function abrirAlteracaoRefeicao(index) {
        const novaLista = [...funcionariosCadastrados];
        novaLista[index].mostrarAlterarRefeicao = true; // Mostra os campos de alteração
        
        // Certifique-se de carregar as refeições salvas no estado de refeicaoDiariaIn
        const refeicoesSalvas = novaLista[index].refeicoes || []; // Obtém as refeições atuais
        const updatedRefeicaoDiariaIn = [...refeicaoDiariaIn];
        updatedRefeicaoDiariaIn[index] = refeicoesSalvas; // Carrega as refeições salvas no estado
        setRefeicaoDiariaIn(updatedRefeicaoDiariaIn); // Atualiza o estado com as refeições salvas
        
        setFuncionariosCadastrados(novaLista);
    }

    async function salvarNovaRefeicao(index) {
        try {
            const funcionario = funcionariosCadastrados[index];
            
            // Usa o array atualizado com os checkboxes
            const novaRefeicaoArray = refeicaoDiariaIn[index] || []; // Verifica se existem novas seleções
            
            // Caminho para o documento do funcionário na subcoleção
            const docRef = doc(db, 'contratantes', cnpj, 'funcionario', funcionario.email);
    
            // Atualiza os dias de folga no Firebase, sobrescrevendo os antigos
            await updateDoc(docRef, { refeicoes: novaRefeicaoArray });
    
            // Atualiza o estado local, substituindo a folga atual pela nova lista de folgas
            const novaLista = [...funcionariosCadastrados];
            novaLista[index].refeicoes = novaRefeicaoArray; // Atualiza o estado da folga com a nova lista
            novaLista[index].mostrarAlterarRefeicao = false; // Fecha o campo de alteração
            setFuncionariosCadastrados(novaLista);
    
            Toast.success('Refeições alteradas com sucesso!');
        } catch (error) {
            console.error("Erro ao salvar refeições:", error);
            Toast.error('Erro ao salvar refeições.');
        }
    }

    function cancelarAlteracaoRefeicao(index) {
        const novaLista = [...funcionariosCadastrados];
        novaLista[index].mostrarAlterarRefeicao = false; // Oculta os campos
        setFuncionariosCadastrados(novaLista);
    }

  return (
    <div className="MenuAdmin">
        <div>
            <h1>Quantidade de funcionários cadastrados: {qtdFuncionariosCadastrados}</h1>
            {!verFuncionariosCadastrados ? (
                <button onClick={() => setVerFuncionariosCadastrados(true)}>Ver Funcionarios</button>
            ) : (
                <div>
                    <button onClick={() => setVerFuncionariosCadastrados(false)}>Esconder Funcionarios</button> 
                    {funcionariosCadastrados ? (
                        <div>
                            <h4>Funcionários cadastrados:</h4>
                            {funcionariosCadastrados.map((funcionario, index) => (
                            <div key={index} className='lista'>
                                <span>funcionário {index + 1}: {funcionario.email}</span><br/>
                                <span>Folga: {funcionario.folga.join(", ")}</span> {/* Se for array, exibe os valores separados por vírgula */}
                                

                                {funcionario.mostrarAlterarFolga ? (
                                    <div>
                                    {/* Campos para selecionar novos dias de folga com checkboxes */}
                                    <div className="dias-semana">
                                        {diasDaSemana.map((dia) => (
                                            <div key={dia.value}>
                                                <input
                                                    type="checkbox"
                                                    id={`${dia.value}-${index}`}
                                                    name={`folga-${index}`}
                                                    value={dia.value}
                                                    checked={folgaSemanal[index]?.includes(dia.value) || false} // Verifica se o dia já está selecionado
                                                    onChange={(e) => handleFolgaChange(e, index, dia.value)} // Usa a mesma função para alterar
                                                />
                                                <label htmlFor={`${dia.value}-${index}`}>{dia.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <button className='salvar' onClick={() => salvarNovaFolga(index)}>Salvar</button>
                                    <button className='salvar' onClick={() => cancelarAlteracaoFolga(index)}>Cancelar</button>
                                </div>
                                ) : ( 
                                    <button className='alterar' onClick={() => abrirAlteracaoFolga(index)}>Alterar Folga</button>
                                )}
                                <span>
                                    Refeições: {Array.isArray(funcionario.refeicoes) ? funcionario.refeicoes.join(", ") : "Nenhuma refeição selecionada"}
                                </span><br/> {/* Se for array, exibe os valores separados por vírgula */}
                                {funcionario.mostrarAlterarRefeicao ? (
                                    <div>
                                    {/* Campos para selecionar novos dias de folga com checkboxes */}
                                    <div className="dias-semana">
                                        {refeicoes.map((refeicao) => (
                                            <div className="check" key={refeicao.value}>
                                                <input
                                                    type="checkbox"
                                                    id={`${refeicao.value}-${index}`}
                                                    name={`refeicao-${index}`}
                                                    value={refeicao.value}
                                                    checked={refeicaoDiariaIn[index]?.includes(refeicao.value) || false} // Mantém true se não houver valores
                                                    onChange={(e) => handleRefeicaoChange(e, index, refeicao.value)}
                                                />
                                                <label htmlFor={`${refeicao.value}-${index}`}>{refeicao.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <button className='salvar' onClick={() => salvarNovaRefeicao(index)}>Salvar</button>
                                    <button className='salvar' onClick={() => cancelarAlteracaoRefeicao(index)}>Cancelar</button>
                                </div>
                                ) : ( 
                                    <button className='alterar' onClick={() => abrirAlteracaoRefeicao(index)}>Alterar Refeiçoes</button>
                                )}
                                
                                <button onClick={() => removerFuncionario(index)} className="remover">
                                <FaTrash />
                                </button>
                            </div>
                            ))}
                        </div>
                        ) : (
                        <div>Nenhum funcionário cadastrado.</div>
                    )}
                </div>
            )}
            
            
            <h4>Adicionar funcionário</h4>
            <button className="adicionar" onClick={() => handleAdicionarFuncionario()}>
                <FaPlus />
                Adicionar funcionário
            </button>
            {adicionarFuncionario ? (
                <div className='cadastro'>
                    <label>*Quantidade de Funcionarios</label>
                    <input
                    placeholder="digite a quantidade de funcionarios"
                    value={qtdFuncionarios}
                    onChange={(e) => setQtdFuncionarios(e.target.value)}
                    autoCapitalize="none"
                    type="number"
                    />
                    {qtdFuncionarios > 0 ? (
                    Array.from({ length: parseInt(qtdFuncionarios) }).map((_, index) => (
                    <div className='funcionario' key={index}>
                        <label>*Email do funcionario {index + 1}</label>
                        <input
                        placeholder={`Digite o email do funcionario ${index + 1}`}
                        value={emailFuncionario[index] || ''}
                        onChange={(e) => {
                            const updatedEmailFuncionario = [...emailFuncionario];
                            updatedEmailFuncionario[index] = e.target.value;
                            setEmailFuncionario(updatedEmailFuncionario);
                        }}
                        autoCapitalize="none"
                        type="email"
                        />
                        <label>*Nome do funcionario {index + 1}</label>
                        <input
                        placeholder={`Digite o nome do funcionario ${index + 1}`}
                        value={nomeFuncionario[index] || ''}
                        onChange={(e) => {
                            const updatedNomeFuncionario = [...nomeFuncionario];
                            updatedNomeFuncionario[index] = e.target.value;
                            setNomeFuncionario(updatedNomeFuncionario);
                        }}
                        type="text"
                        />
                        <label>*CPF do funcionario {index + 1}</label>
                        <IMaskInput
                            id="cpf"
                            value={cpfFuncionario[index] || ""}
                            mask="000.000.000-00"
                            placeholder="Digite o seu CPF"
                            onChange={(e) => {
                                const updatedCpfFuncionario = [...cpfFuncionario];
                                updatedCpfFuncionario[index] = e.target.value;
                                setCpfFuncionario(updatedCpfFuncionario);
                            }}
                            autocomplete="off"
                        />
                            <div>
                                <label>*Dias de folga do funcionário {nomeFuncionario[index] || ''}</label>
                                <div className="dias-semana">
                                    {diasDaSemana.map((dia) => (
                                        <div className="check" key={dia.value}>
                                            <input
                                                type="checkbox"
                                                id={`${dia.value}-${index}`}
                                                name={`folga-${index}`}
                                                value={dia.value}
                                                checked={folgaSemanal[index]?.includes(dia.value) || false}
                                                onChange={(e) => handleFolgaChange(e, index, dia.value)}
                                            />
                                            <label htmlFor={`${dia.value}-${index}`}>{dia.label}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <label>*Refeições liberadas {nomeFuncionario[index] || ''}</label>
                                <div className="dias-semana">
                                    {refeicoes.map((refeicao) => (
                                        <div className="check" key={refeicao.value}>
                                            <input
                                                type="checkbox"
                                                id={`${refeicao.value}-${index}`}
                                                name={`refeicao-${index}`}
                                                value={refeicao.value}
                                                checked={refeicaoDiariaIn[index]?.includes(refeicao.value) || false} // Mantém true se não houver valores
                                                onChange={(e) => handleRefeicaoChange(e, index, refeicao.value)}
                                            />
                                            <label htmlFor={`${refeicao.value}-${index}`}>{refeicao.label}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )) ): null}
                    <button className="home-button" onClick={incluirFuncionarios}>
                    Incluir funcionarios
                    </button>
                </div>
            ) : <div></div>}
        </div>
     
      
    </div>
  )
};
