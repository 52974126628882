import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { useNavigate} from "react-router-dom";

import { doc, getDoc, collection, getDocs } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../service/firebaseConfig';

import { AuthContext } from '../../routes/contexts/AuthContext';

import './styles.css';
import { toast } from 'react-toastify';

export const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const [cpf, setCpf] = useState(0);
  const [cnpj, setCnpj] = useState(0);
  const [end, setEnd] = useState('');
  const [tipoCadastro, setTipoCadastro] = useState(0);
  const [cadastroValidado, setCadastroValidado] = useState(false);
  const [morador, setMorador] = useState(false);
  const [gerente, setGerente] = useState(false);
  const [porteiro, setPorteiro] = useState(false);
  const [empresa, setEmpresa] = useState(false);
  const [admin, setAdmin] = useState(false);
  const navigate = useNavigate();

  

  useEffect(() => {
    async function usuario() {
      await buscaDados();
    }
    usuario();
  }, []);

  async function buscaDados(){
    const docRef = doc(db, 'usuarios', email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        setTipoCadastro(docSnap.data().tipoCadastro)
        setCadastroValidado(docSnap.data().cadastroValidado)
    //    setCpf(docSnap.data().cpf)
      //  setEnd(docSnap.data().end)
        //setCnpj(docSnap.data().cnpj)

    }
    await buscaMorador();
    await buscaGerente();
    await buscaPorteiro();
    await buscaEmpresa();
    await buscaAdmin();
  }

  async function buscaMorador(){
    const colectionRef = collection(db, 'usuarios', email, 'morador');
    const querySnapshot = await getDocs(colectionRef);
    if (!querySnapshot.empty) {
      setMorador(true);
      // Se precisar acessar os documentos, você pode iterar sobre querySnapshot.docs
      querySnapshot.forEach((doc) => {
        setCnpj(doc.id)
      });
    }
  }

  async function buscaGerente(){
    const colectionRef = collection(db, 'usuarios', email, 'gerente');
    const querySnapshot = await getDocs(colectionRef);
    if (!querySnapshot.empty) {
      setGerente(true);
      
      // Se precisar acessar os documentos, você pode iterar sobre querySnapshot.docs
      querySnapshot.forEach((doc) => {
        setCnpj(doc.id)
      });
    }
  }

  async function buscaPorteiro(){
    const colectionRef = collection(db, 'usuarios', email, 'porteiro');
    const querySnapshot = await getDocs(colectionRef);
    if (!querySnapshot.empty) {
      setPorteiro(true);
      
      // Se precisar acessar os documentos, você pode iterar sobre querySnapshot.docs
      querySnapshot.forEach((doc) => {
        setCnpj(doc.id)
      });
    }
  }

  async function buscaEmpresa(){
    const colectionRef = collection(db, 'usuarios', email, 'dono');
    const querySnapshot = await getDocs(colectionRef);
    if (!querySnapshot.empty) {
      setEmpresa(true);
      // Se precisar acessar os documentos, você pode iterar sobre querySnapshot.docs
      querySnapshot.forEach((doc) => {
        setCnpj(doc.id)

      });
    }
  }

  async function buscaAdmin(){
    if (email === 'hesleicosta@gmail.com') {
      setAdmin(true)
    }
  }

  function handleAtualizarCadastro(){
    navigate('/atualizar')
  }

  function handleMenuAdmin(){
    navigate('/menuadmin')
  }

  function handleMenuContratante() {
    navigate('/menucontratante', { state: { cnpj } });
  }

  function handleMenuGerente(){
    navigate('/menugerente', { state: { cnpj } });
  }

  function handleMenuMorador(){
    navigate('/menumorador', { state: { cnpj } })
  }

  function handleMenuPortaria(){
    navigate('/menuportaria', { state: { cnpj } })
  }

  function handleMeusCheckin() {
    toast.info(`em construção`);
  }
  
  return (
    <div className="home">
      {tipoCadastro === 0 ? (
        <div>
          <h1>Você possui o cadastro basico, esse cadastro da direito a entrada apenas a alguns estabelecimentos</h1>
          <h1>Inclua mais informações no cadastro para ter acesso a mais estabelecimentos.</h1>
        </div>
      ) : (
      tipoCadastro === 1 ?(
        <div>
          <h1>Você possui o cadastro intermediario, esse cadastro da direito a entrada em varios estabelecimentos, mas não todos</h1>
          <h1>Inclua mais informações no cadastro para ter acesso a mais estabelecimentos.</h1>
        </div>

      ) : (
      tipoCadastro === 2 ?(
        <div>
          <h1>Cadastro completo, você pode fazer o check-in em qualquer lugar </h1>
        </div>
      ): <div></div>))}
      <button className="home-button" onClick={handleAtualizarCadastro}>
        Atualizar Cadastro
      </button>
      <button className="home-button" onClick={handleMeusCheckin}>
        Meus checkins
      </button>
      {morador? (
        <div>
          <button className="home-button" onClick={() => handleMenuMorador()}>
            Menu Morador
          </button>
        </div>
      ) : null }
      {porteiro? (
        <div>
          <button className="home-button" onClick={() => handleMenuPortaria()}>
            Menu Portaria
          </button>
        </div>
      ) : null }
      {gerente? (
        <div>
          <button className="home-button" onClick={() => handleMenuGerente()}>
            Menu Gerente
          </button>
        </div>
      ) : null }
      {empresa? (
        <div>
          <button className="home-button" onClick={() => handleMenuContratante()}>
            Ir para o Menu Contratante
          </button>
        </div>
      ) : null }
      {admin? (
          <buttom className="home-button" onClick={() => handleMenuAdmin()}>
              Menu Administrador
          </buttom>
      ) : null }
      {/* 
      {morador ? (
        <div>
            <button className="home-button" onClick={handleAtualizarCadastro}>
              Meus checkin
            </button>
        </div>
      ) : (
        morador? (
            <div>
              <button className="home-button" onClick={() => handleMenuMorador(cnpj)}>
                Menu Morador
              </button>
            </div>

      ) :
        porteiro? (
            <div>
            <button  onPress={() => handleMenuPortaria()}>
                <h1 style={{color: '#bad1f7', fontFamily: 'Nunito_600SemiBold',}}>Menu Portaria</h1>
            </button>
        </div>

      ) : 
        gerente? (
            <div>
                <button className="home-button" onClick={() => handleMenuGerente(cnpj)}>
                Menu Gerente
                </button>
            </div>

      ) : empresa? (
            <div>
                <button className="home-button" onClick={() => handleMenuContratante(cnpj)}>
                  Ir para o Menu Contratante
                </button>
            </div>

      ) : 
        admin? (
            <div>
                <buttom className="home-button" onClick={() => handleMenuAdmin()}>
                    Menu Administrador
                </buttom>
        </div>

      ) : (<div></div>)
        
      (<div></div>)
      ) 
      }*/}
    </div>
  )
};
