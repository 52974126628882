import React, { useState, useEffect, useContext, useRef } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { FaTrash, FaPlus } from 'react-icons/fa';
import { IMaskInput } from "react-imask";
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../service/firebaseConfig';

import { AuthContext } from '../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import Toast from "../../components/Toast";
import { doc, setDoc, getDocs, collection, deleteDoc } from "firebase/firestore";

import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import Logo from '../../assets/image/logo2.png';
//import downloadIcon from './download.png';
//import QRCodeGenerator from './QRCodeGenerator';

import './styles.css';

export const MenuContratante = () => {

  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const location = useLocation();
  const cnpj = location.state ? location.state.cnpj : null;
  const [gerentesCadastrados, setGerentesCadastrados] = useState([]);
  const [cpfGerente, setCpfGerente] = useState('');
  const [emailGerente, setEmailGerente] = useState('');
  const [emailUsu, setEmailUsu] = useState('');
  const [cpf, setCpf] = useState(0);
  const [nomeGerente, setNomeGerente] = useState('');
  const [atualizar, setAtualizar] = useState(false);

  const [adicionar, setAdicionar] = useState(false);

  const [dataNascimento, setDataNascimento] = useState('');
  const [cel, setCel] = useState('');
  const [selectedUf, setSelectedUf] = useState(null);
  const [selectedCidade, setSelectedCidade] = useState(null);
  const [cep, setCep] = useState('');
  const [end, setEnd] = useState('');
  const [tipoDoc, setTipoDoc] = useState('');
  const [numDoc, setNumDoc] = useState('');
  const [orgao, setOrgao] = useState('');
  const [placa, setPlaca] = useState('');
  const [profissao, setProfissao] = useState('');
  const [genero, setGenero] = useState('');
  const [tipoCadastro, setTipoCadastro] = useState(0);
  const [cadastroValidado, setCadastroValidado] = useState(false);
  const url = `https://checkinfacil.com.br/qrcode/:${cnpj}`;
  const title = 'Check-in Fácil';
  const qrCodeRef = useRef(null);
  const logoStyle = {
    position: 'absolute',
    width: '5%', // Ajuste o tamanho da logo conforme necessário
    top: '40%', // Ajuste a posição vertical da logo conforme necessário
    left: '47.5%', // Ajuste a posição horizontal da logo conforme necessário
  };

  const navigate = useNavigate();

  useEffect(() => {
    async function usuario() {
      await buscaDados();
    }
    usuario();
  }, [atualizar]);
  

  async function buscaDados(){
    try {
      const colectionRef = collection(db, 'contratantes', cnpj, 'gerente');
      const querySnapshot = await getDocs(colectionRef);
      const gerentes = [];

      if (!querySnapshot.empty) {
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const gerenteData = doc.data();

        // Extrair os campos email, cpf, nome e folga
            const gerente = {
              email: gerenteData.email, // o ID do documento é o email
            };

          // Adiciona o funcionário ao array de funcionários
          gerentes.push(gerente);
          })
        );

      // Atualiza o estado com o array de funcionários cadastrados
      setGerentesCadastrados(gerentes);
      } else {
        setGerentesCadastrados(null)
      }
    } catch (error) {
        console.error("Erro ao buscar gerentes:", error);
    }
  }

  async function incluirGerente() {
    try {

      const gerenteTab = collection(db, 'contratantes', cnpj, 'gerente');
      const incluirGerenteTab = doc(gerenteTab, emailGerente)

      let tabGerenteData = {
          email: emailGerente, // Email
          ativo: true
      };

      await setDoc(incluirGerenteTab, tabGerenteData);

      const usuarioTab = collection(db, 'usuarios', emailGerente, 'gerente');
      const incluirUsuarioTab = doc(usuarioTab, cnpj)

      let tabUsuarioData = {
        ativo: true
      };

      await setDoc(incluirUsuarioTab, tabUsuarioData)
      Toast.success('gerentes incluidos com sucesso.');
      setAdicionar(false)
      setAtualizar(!atualizar)
      setEmailGerente('')
      setCpfGerente('')
      setNomeGerente('');
    } catch (error) {
        console.log('error = ', error)
        toast.error('Erro ao incluir gerente.');
    }
  }

  const titleContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  };
  
  const logoTitleStyle = {
    marginLeft: '10px', // Espaçamento entre o título e a logo
    width: '50px', // Tamanho ajustado da logo ao lado do título
  };

  async function removerGerente(index) {
    try {
      const emailRemovido = gerentesCadastrados[index].email; // Obtém o email do funcionário a ser removido
          
      // Caminho para o documento do funcionário dentro da subcoleção
      const docRef = doc(db, 'contratantes', cnpj, 'gerente', emailRemovido);

      // Exclui o documento do funcionário
      await deleteDoc(docRef);

      const usuRef = doc(db, 'usuarios', emailRemovido, 'gerente', cnpj);

      await deleteDoc(usuRef);

      // Remover funcionário da lista exibida no estado
      const novaLista = [...gerentesCadastrados];
      novaLista.splice(index, 1);
      setGerentesCadastrados(novaLista);

      // Exibir feedback de sucesso
      Toast.success('Gerente removido com sucesso!');
    } catch (error) {
        console.error("Erro ao remover gerente:", error);
        Toast.error('Erro ao remover gerente.');
    }
  }

  function adicionarGerente() {
      setAdicionar(true);
  }

  function relatorios() {
  }

  const handleDownload = async () => {
    if (!qrCodeRef.current) return;

    const canvas = await html2canvas(qrCodeRef.current);
    const imageUrl = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'qrcode.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="MenuAdmin">
      <h1>Olá {email}</h1>
      <button className="adicionar" onClick={() => relatorios()}>
        Relatorio de Visitas
      </button>
      <h4>Gerentes cadastrados</h4>
      {gerentesCadastrados.map((gerente, index) => (
        <div key={index} className='lista'>
          <span>Email do gerente {index + 1}: {gerente.email}</span>
          <button onClick={() => removerGerente(index)} className="remover">
            <FaTrash />
          </button>
        </div>
      ))}
      <h4>adicionar Gerente</h4>
      <button className="adicionar" onClick={() => adicionarGerente()}>
        <FaPlus />
        Adicionar Gerente
      </button>
      {adicionar? (
        <div className='cadastro'>
          <label>*Email do Gerente/Sindico</label>
          <input
            
            placeholder="digite o email do gerente"
            value={emailGerente}
            onChange={(e) => setEmailGerente(e.target.value)}
            autoCapitalize="none"
            type="email"
          />
           <button className="home-button" onClick={incluirGerente}>
            Incluir Gerente
          </button>
        </div>
        
      ) : <div></div>}
     
     <div className='imagemqrcode'>
     <div ref={qrCodeRef} style={{ textAlign: 'center', width: '340px', height: '420px', position: 'relative' }}>
          <div style={titleContainerStyle}>
            <img src={Logo} alt="Logo" style={logoTitleStyle} />
            <h2 style={{ color: '#80d8e4' }}>{title}</h2>
          </div>
          <QRCode value={url} size={300} fgColor="#01505A" bgColor="#fff" />
        </div>
        <br />
      </div>
      <button onClick={handleDownload}>
        Download QR Code
      </button>
    </div>
  )
};
